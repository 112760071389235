/* ———————————————————————————————————————— */
/* Components - About Blocks
/* ———————————————————————————————————————— */

.c-about-blocks {
  margin-bottom: $spacing-base;

  .c-about-blocks__title {
    margin-bottom: $spacing-alt-double;
    @include font-primary--light;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }

  .c-about-blocks__content {
    margin-bottom: $spacing-alt-double;
    @include font-secondary--light;
  }

  @include responsive(tablet) {
    margin-top: 0;
    margin-bottom: 0;
  }

}