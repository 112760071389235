/* ———————————————————————————————————————— */
/* JD - Base List
/* ———————————————————————————————————————— */

ul, ol {
  margin-bottom: $spacing-base;
  list-style-type: disc;
}

 li {
  margin-left: 18px;
 }