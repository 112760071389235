.c-treatment-logo {
    z-index: 10;



    .c-treatment-logo__inner {
        background-color: #fff;
        border-radius: $border-radius;
        padding: 80px 40px;
        box-shadow: 10px 10px 23px -3px rgba(0,0,0,0.2);
        position: absolute;
        right: $spacing-base;
        transform: translateY(-75%);
    }

    &.treatment{
        .c-treatment-logo__inner {
           width: calc(((100% / 12) * 3) - 50px);
           margin-right: 10px;
       }
    }

    img {
        height: 55px;
        width: auto;
    }

    &.treatment{
        img{
            width: 100%;
            height: 100%;
            max-height: 55px;
        }
    }


    @include responsive(tablet) {
        display: none;
    }

}

.c-treatment-logo--picture {

    .c-treatment-logo__inner {
        padding: 5px 5px;
        line-height: 0;
    }

    img {
        height: 200px;
        width: auto;
        max-width: 300px;
    }

}