/* ———————————————————————————————————————— */
/* Components - Treatment Before & After
/* ———————————————————————————————————————— */

.c-treatment-before-after {
    // padding-top: $spacing-alt-double;
    padding-bottom: $spacing-triple;

    @include responsive(tablet) {
        padding-top: 0;
        padding-bottom: 0;
    }
    
    
    
    
    .c-treatment-before-after__curve {
        fill: $color-secondary;
        position: absolute;
        bottom: 100%;
        left: 0;
        transform: scale(1.05);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }


    .c-treatment-before-after__title {
        color: #fff;
        margin-bottom: $spacing-alt-double;
    }


    .c-treatment-before-after__intro {
        margin-bottom: $spacing-alt-double;
        color: #fff;
        max-width: 75%;

        @include responsive(tablet) {
            max-width: 100%;
        }
    }

}