/* ———————————————————————————————————————— */
/* Components - Treatment Blocks
/* ———————————————————————————————————————— */

.c-treatment-blocks {
    // padding-top: $spacing-alt-double;
    padding-bottom: $spacing-triple;



    .c-treatment-blocks__curve {
        fill: $color-primary;
        position: absolute;
        bottom: 100%;
        left: 0;
        transform: scale(1.05);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }


    .c-treatment-blocks__title {
        color: #fff;
        margin-bottom: $spacing-alt-double;

        @include responsive(tablet) {
            margin-bottom: $spacing-base;
        }

  }



    @include responsive(tablet) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.c-treatment-blocks--alt {
    .c-treatment-blocks__title {
        color: $color-primary;
    }
    .c-treatment-blocks__curve {
        fill: #fff;
    }
}

.c-treatment-blocks--white {
    .c-treatment-blocks__title {
        color: $color-primary;
        &.purple {
            color: $color-secondary;
        }
    }
    .c-treatment-blocks__curve {
        fill: #ffffff;
    }
}

.c-treatment-blocks--primary {
    .c-treatment-blocks__title {
        color: #ffffff;
    }
    .c-treatment-blocks__curve {
        fill: $color-primary;
    }
}

.c-treatment-blocks--secondary {
    .c-treatment-blocks__title {
        color: #fff;
    }
    .c-treatment-blocks__curve {
        fill: $color-secondary;
    }
}

.c-treatment-blocks--grey {
    .c-treatment-blocks__title {
        color: $color-primary;
        &.purple {
            color: $color-secondary;
        }
    }
    .c-treatment-blocks__curve {
        fill: $color-grey;
    }
}

.c-treatment-blocks--other {
    z-index: 12;
}