/* ———————————————————————————————————————— */
/* Components - Membership
/* ———————————————————————————————————————— */

.c-membership {
    // padding-top: $spacing-alt-double;
    padding-bottom: $spacing-triple;

  .c-membership__curve {
    position: absolute;
    bottom: 100%;
    fill: $color-secondary;
    color: #fff;
    transform: scale(1.05);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }

  .c-membership__title {
    padding-bottom: $spacing-alt-double;
    color: #fff;
  }


  .g__item:last-of-type {

    .c-membership__item {
      margin-top: 40px;

      &:first-of-type {
        margin-top: 0;
        margin-bottom: $spacing-base-alt;
      }

      @include responsive(tablet) {
        &:first-of-type {
          margin-top: 40px;
        }
      }

    }

  }


  .c-membership__item {
    background-color: #fff;
    padding: $spacing-double;
    border-radius: 3px;

    @include responsive(tablet) {
      padding: $spacing-base;
    }

  }


  .c-membership__item-title {
    color: $color-secondary;
    letter-spacing: 0.4px;
    margin-bottom: $spacing-double;
    @include font-primary--semi;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }
    
  }

  li {
    position: relative;
    margin-bottom: $spacing-base-alt;
    padding-left: 35px;
    @include font-secondary--normal;

    i {
      color: $color-primary;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      position: absolute;
    }

  }

  .c-membership__button {
    margin-top: $spacing-alt-double;
    padding-left: $spacing-double;
    padding-right: $spacing-double;
  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}