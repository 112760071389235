.c-black-friday {
	position: relative;
	overflow: hidden;
	background-color: #000;
	
	.o-container {
		z-index: 2;
	}

	&.c-black-friday--primary {
		.c-black-friday__item {
			em {
				color: $color-secondary;
			}

			&::before {
				background-color: $color-primary;
			}
		}
	}
}


	.c-black-friday__title {
		color: #fff;
		margin-bottom: 35px;
		font-weight: 700;
		max-width: 75%;
		margin-top: 25px;

		@include responsive(mobile) {
			max-width: 100%;
			margin-top: 0;
			margin-bottom: 20px;
		}
		
	}


	.c-black-friday__intro {
		color: #fff;
		max-width: 90%;

		b, strong {
			font-weight: 700;
			// text-decoration: underline;
			color: #fff;
		}

		p {
			color: #fff;
			font-size: 30px;
			line-height: 40px;
			margin-bottom: 40px;

			@include responsive(tablet) {
				font-size: 22px;
				line-height: 36px;
				margin-bottom: 35px;
			}

			@include responsive(mobile) {
				margin-bottom: 25px;
				font-size: 16px;
				line-height: 20px;
			}
		}

		@include responsive(mobile) {
			max-width: 100%;
		}
	}


	.c-black-friday__text {
		max-width: 75%;

		b, strong {
			font-weight: 700;
			// text-decoration: underline;
			color: #fff;
		}

		p {
			margin-top: 30px;
			color: #fff;
			margin-bottom: 0;
		}

		@include responsive(mobile) {
			max-width: 100%;
		}
	}

	.c-black-friday__list {
		list-style-type: none;
		margin-bottom: 0;
	}


		.c-black-friday__item {
			margin-bottom: 28px;
			margin-left: 0;
			padding-left: 45px;
			position: relative;

			&:last-of-type {
				margin-bottom: 0;
			}

			&::before {
				content: '\f00c';
				font-family: FontAwesome;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 100%;
				background-color: $color-secondary;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				font-size: 18px;
				height: 35px;

				@include responsive(tablet) {
					width: 25px;
					font-size: 16px;
					height: 25px;
				}
			}

			p {
				margin-bottom: 0;
				font-size: 24px;
				line-height: 28px;
				font-size: 18px;
				color: #fff;
				line-height: 24px;

				@include responsive(tablet) {
					font-size: 18px;
					line-height: 24px;
				}
			}

			em {
				color: $color-primary;
				font-weight: $font-bold;
			}

			b, strong {
				font-weight: $font-bold;
				color: #fff;
				// text-decoration: underline;
			}
		}


		.c-black-friday__button {
			margin-top: 35px;
			margin-bottom: 30px;
		}


		.c-black-friday__terms {
			color: #fff;
			font-size: 12px;
			line-height: 16px;
			display: block;
			width: 100%;
			max-width: 80%;
		}


		.c-black-friday__label {
			width: 100%;
			margin-top: 25px;
			position: relative;
			display: block;
			font-size: 30px;
			line-height: 36px;
			color: #fff;
			text-transform: uppercase;
			margin-bottom: 20px;


			@include responsive(laptop) {
				margin-top: 50px;
			}

			@include responsive(mobile) {
				margin-bottom: 20px;
				line-height: 30px;
				margin-bottom: 150x;
			}
 		}


		.c-black-friday__date {
			border: 1px solid $color-primary;

			@include responsive(tablet) {
				margin-bottom: 35px;
			}

			@include responsive(mobile) {
				margin-bottom: 15px;
			}
		}	
	
	
			.c-black-friday__main {
				width: 100%;
				background-color: $color-primary;
				text-align: center;
				padding: 50px 25px;
				font-size: 94px;
				line-height: 94px;
				text-transform: uppercase;
				font-weight: 700;
				color: #fff;

				@include responsive(tablet) {
					font-size: 54px;
					line-height: 54px;
					padding: 30px 15px;
				}
	
				@include responsive(mobile) {				
					padding: 25px 15px;
					font-size: 36px;
					line-height: 40px;
				}
			}


			.c-black-friday__wrap {
				@include clearfix;
			}
	
	
			.c-black-friday__date-item {
				float: left;
				width: calc(100% / 3);
				display: block;
				text-align: center;
				color: #fff;
				padding: 20px 15px;
				border-left: 1px solid $color-primary;
				font-size: 44px;
				line-height: 46px;
				text-transform: uppercase;
				color: #fff;
	
				&:first-of-type {
					border-left: 0;
				}

				@include responsive(tablet) {
					font-size: 28px;
					line-height: 26px;
					padding: 20px 15px;
				}
	
				@include responsive(mobile) {
					font-size: 18px;
					line-height: 16px;
				}
			}
	
				.c-black-friday__date-type {
					width: 100%;
					display: block;
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
				}



	.c-black-friday__curve {
		fill: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: scale(1.05); 
	
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
			display: none;
		}
	}


	.c-black-friday__large {
		top: 5vw;
		left: -10vw;
	}

	.c-black-friday__medium {
		top: 15vw;
		right: -6vw;
	}

	.c-black-friday__small {
		left: 20%;
		bottom: -4vw;
	}