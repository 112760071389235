/* ———————————————————————————————————————— */
/* Components - Dropdown
/* ———————————————————————————————————————— */

.c-dropdown {
    margin-right: 15px;
    position: relative;;

    &__selected {
      position: relative;
      float: left;
      border-radius: 3px;
      padding: 15px;
      padding-right: 150px;
      z-index: 2;
      color: #fff;
      background-color: $color-primary;

      &.active {
        border-radius: 3px 3px 0 0;
      }

      @include responsive(mobile) {
        width: 100%;
      }

    }

    &__arrow {
      float: left;
      position: absolute;
      right: 15px;
      top: 13px;
      color: #fff;
      pointer-events: none;

      i {
        position: relative;
        z-index: 10;
      }

    }
    
    &__options {
      display: none;
      position: absolute;
      top: 100%;
      background: $color-primary--dark;
      width: 100%;
      z-index: 99;

      ul {
        margin-bottom: 0;
      }

      li {
        transition: background 300ms ease-in-out;
        border-top: 1px solid $color-primary;
        background: $color-primary--dark;
        padding: $spacing-half $spacing-base;
        width: 100%;
        color: #fff;
        float: left;
        position: relative;

        &:hover {
          background-color: darken($color-primary--dark, 15);
        }

      }

      a {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        color: #fff;
      }

    }

    &:last-of-type {
      margin-right: 0;
    }


    @include responsive(mobile) {
      width: 100%;
      display: inline-block;
      margin-bottom: $spacing-base;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

}