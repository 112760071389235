/* ============================================================
    * Element - Bubble
============================================================ */

/**
	* Styling for bubbles that appear in the sections,
	* there are modifiers to postion and size in this file.
*/

.e-bubble {
	position: absolute;
	border-radius: 100%;
}



	.e-bubble--small {
		width: 8vw;
		height: 8vw;
	}


	.e-bubble--medium-small {
		width: 20vw;
		height: 20vw;
	}


	.e-bubble--medium {
		width: 25vw;
		height: 25vw;
	}


	.e-bubble--large {
		width: 30vw;
		height: 30vw;
	}


	.e-bubble--massive {
		width: 155vw;
		height: 155vw;
	}


	.e-bubble--secondary {
		background-color: rgba(64,58,94,0.46);
	}


	.e-bubble--primary {
		background-color: rgba(65,191,189,0.2);
	}


	.e-bubble--primary-light {
		background-color: rgba(65,191,189,0.15);
	}

	.e-bubble--grey {
		background-color: rgba(255,255,255,0.1);
	}



