/* ———————————————————————————————————————— */
/* Components - Single Post
/* ———————————————————————————————————————— */

.c-single-career {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-alt-double;
  
  
  .c-single-career__content {
    
    img {
      width: 100%;
      height: auto;
    }

    h2, h3 {
      margin-bottom: $spacing-triple;

      @include responsive(tablet) {
        margin-bottom: $spacing-alt-double;
      }

    }

    h1,h2,h3,h4,h5 {
      color: $color-secondary;
    }

    p {
      @include font-secondary--light;
    }

  }



  .c-single-career__related {
    padding-top: $spacing-quad;
    padding-bottom: 35px;

    @include responsive(tablet) {
      padding-top: 45px;
      padding-bottom: 0;
    }

  }

    .c-single-career__related-title {
      color: $color-secondary;
      margin-bottom: $spacing-double;

      @include responsive(tablet) {
        margin-bottom: $spacing-base;
      }

    }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}