/* ———————————————————————————————————————— */
/* Components - Treatment Top
/* ———————————————————————————————————————— */

.c-treatment-top {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;



  .c-treatment-top__reviews {
      background: #fff;
      box-shadow: 10px 10px 23px -3px rgba(0,0,0,0.2);

      iframe {
          background: #fff;
      }

  }


  .c-treatment-top__sub {
    margin-bottom: $spacing-base;
    background-color: $color-primary;
    border-radius: 3px;
    margin-bottom: auto;
    overflow: visible !important;

    @include responsive(tablet) {
        margin-top: $spacing-base;
    }

    .c-treatment-top__inner {
        padding: $spacing-base;
    }

  }


    .c-treatment-top__item {
      border-bottom: 1px solid white;
      padding: 10px 0px;

      @include responsive(tablet) {
        &:first-of-type {
            display: none;
        }
      }

    }


        .c-treatment-top__link {
            color: #fff;
            margin-bottom: 5px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            @include font-size(12px, 20px);

            &:hover {
              // font-weight: 600;
              // color: darken($color-primary, 25);
            }

            &.active {
                // color: $color-secondary;
                // font-weight: 600;

                &:hover {
                    // color: darken($color-secondary, 25);
                }
            }
        }




  .c-treatment-top__title {
    color: $color-secondary;
  }

  .c-treatment-top__text {
    @include font-secondary--light;

    h3, h4, h5, h6 {
        color: $color-primary;
    }

    ul {
        margin-bottom: $spacing-base;
    }

    li {
        margin-bottom: 5px;
        line-height: 26px;
        
        &:last-of-type {
            margin-bottom: 0;
        } 
    }
  }

  .c-treatment-top__button {
    margin-top: $spacing-alt-double;
  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}