/* ———————————————————————————————————————— */
/* Components - Career
/* ———————————————————————————————————————— */

.c-career {
  margin-top: $spacing-alt-double;

  &.c-career--taken {
    opacity: 0.2;
    pointer-events: none;
  }
  

  .c-career__title {
    color: $color-secondary;
    margin-bottom: $spacing-half;
  }
  

  .c-career__info {
    display: inline-block;
    margin-right: $spacing-half;
    @include font-secondary--light;

    &:last-of-type {
      margin-right: 0;
    }

    span {
      margin-right: $spacing-quarter;
      text-transform: uppercase;
      @include font-secondary--semi;
    }

  }


  .c-career__intro {
      margin-top: $spacing-base-alt;
     @include font-secondary--light;
  }


  &:nth-of-type(-n+3) {
    margin-top: 0;
  }

  @include responsive(tablet) {
    margin-top: $spacing-alt-double !important;
    
    &:nth-of-type(-n+2) {
      margin-top: 0 !important;
    }

  }


  @include responsive(mobile) {
    margin-top: $spacing-alt-double !important;
    
    &:first-of-type {
      margin-top: 0 !important;
    }

    &:nth-of-type(2) {
      margin-top: $spacing-alt-double !important;
    }

  }

}