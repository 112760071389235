/* ———————————————————————————————————————— */
/* Components - Featured Blocks
/* ———————————————————————————————————————— */

.c-featured-blocks {
    margin-top: $spacing-double;

    &:nth-of-type(-n+2) {
        margin-top: 0;
    }

    @include responsive(tablet) {
        margin-top: $spacing-base !important;

        &:first-of-type {
            margin-top: 0 !important;
        }
    }

}


    .c-featured-blocks__image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        border-radius: 3px;
        // min-height: 250px;
        @include aspect-ratio(280px, 265px);
    }

        .c-featured-blocks__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
        }

        .c-featured-blocks__logo {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-color: #fff;
            height: 50px;
            width: 175px;
            padding: 10px;
        }

            .c-featured-blocks__media {
                width: 100%;
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
            }



    .c-featured-blocks__main {
        padding: $spacing-base;
        padding-top: 0;

        @include responsive(laptop) {
            padding: $spacing-base 0;
        }

        @include responsive(tablet) {
            padding: $spacing-base;
            padding-top: 0;
        }

        @include responsive(mobile) {
            padding: $spacing-base 0;
        }
    }


        .c-featured-blocks__title {
            color: $color-primary;
            margin-bottom: 5px;
        }


        .c-featured-blocks__content {
            color: $color-grey--light;
            margin-bottom: 5px;
        }


        .c-featured-blocks__sub {
            color: $color-secondary;
            margin-bottom: 0;
		}
		

		.c-featured-blocks__sub--alt {
			margin-bottom: 0;
		}
		
	
		.c-featured-blocks__small {
			font-size: 12px;
			width: 100%;
			display: block;
			margin-bottom: 20px;
			margin-top: 5px;
		}