/* ———————————————————————————————————————— */
/* Components - Contact
/* ———————————————————————————————————————— */

.c-contact {
  padding-bottom: $spacing-alt-triple;


  .c-contact__title {
    color: $color-secondary;
    margin-bottom: $spacing-base;
  }


  .c-contact__list {
  }

    .c-contact__item {
    }

      .c-contact__link {
        letter-spacing: 0.4px;
        @include font-primary--semi;
        @include font-size(30px, 40px);

        &:hover {
          color: darken($color-primary, 15);
        }

        @include responsive(mobile) {
          @include font-size(20px, 30px);
        }

      }

  
  @include responsive(tablet) {
    padding-bottom: 0;
  }


}



.gm-style-iw {
  background-color: #fff;
  padding: $spacing-base;
  width: 220px !important;
  color:$color-secondary;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  overflow: visible !important;

  div {
    overflow: visible !important;
  }

  i {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 10;
    cursor: pointer;
  }

  a {
    color:$color-secondary;
    font-weight: 400 !important;
    color:$color-secondary;
  }

  a:last-of-type {
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600 !important;
  }

  p {
    margin-bottom: 0;
    color:$color-secondary;
  }

  ul {
    list-style-type: none;
    margin: 10px 0;
  }

  li {
    margin-left: 0;
  }

  & + div {
    display: none !important;
  }

  &:before {
    content: "";
    width: 0; 
    height: 0; 
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent; 
    border-right: 25px solid #fff;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
  }

}


.close-infobox {
  display: none;
}