/* ———————————————————————————————————————— */
/* Components - Logos
/* ———————————————————————————————————————— */

.c-logos {
  z-index: 18;
  padding: 20px 0;
  background-color: #fff;

  .c-logos__wrapper {
    display: flex;
    align-items: center;
  }

  img {
    transition: filter 300ms ease-in-out;
    margin-right: $spacing-base;
    height: 30px;
    margin-top: 5px;
    filter: grayscale(100%);
    cursor: pointer;

    &.first {
        margin-right: $spacing-double;
    }

    &:hover {
        filter: grayscale(0);
    }

    @include responsive(tablet) {
        display: none;
    }
  }

  svg {
    height: 80px;
    width: auto;
    fill: $color-primary;
    margin: 0;
    padding: 0;
    border: 0;
    margin-right: $spacing-base;

    @include responsive(tablet) {
      height: 70px;
    }

  }

  .c-logos__main {
    height: 80px;
    width: auto;

    @include responsive(tablet) {
      height: 70px;
    }
  }

  use {
    width: 100%;
  }


  .c-logos__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  

  .c-logos__button {
    margin-left: $spacing-base;

    @include responsive(laptop) {
      display: none;
    }

  }


  .c-logos__phone {
    position: relative;
    padding: 0;
    width: 45px;
    height: 45px;
    font-size: 22px;

    i {
        position: absolute;
        left: 0;
        text-align: center;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }

    &:last-of-type {
        display: none;
    }

    @include responsive(tablet) {
        &:first-of-type {
            display: none;
        }   

        &:last-of-type {
            display: block;
        }
    }


  }


  .c-logos__up {
    display: none;
    position: relative;
    padding: 0;
    width: 45px;
    height: 45px;
    font-size: 22px;

    i {
        position: absolute;
        left: 0;
        text-align: center;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }
  }


  &.fixed {
    position: fixed;
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.25);
    top: 0;

    .c-logos__up {
        display: block;
    }

    @include responsive(tablet) {
        padding: 10px 0;

        .c-logos__main { 
            height: 40px;
        }

        svg {
            height: 40px;
        }
    }
  }

}


    .c-logos__mobile-item {
        display: none;
        color: $color-primary;
        font-size: 30px;
        line-height: 30px;
        margin-left: $spacing-base;

        &:first-of-type {
            margin-left: 0;
        }

        &.js-contact-numbers {
            margin-left: 0;
        }


        @include responsive(laptop) {
            display: inline-block;
        }


        @include responsive(mobile) {
            margin-left: 20px;
            font-size: 25px;
        }

    }