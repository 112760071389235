/* ———————————————————————————————————————— */
/* Components - Before and After
/* ———————————————————————————————————————— */

.c-before-after {
	// padding-top: $spacing-alt-double;
	padding-bottom: $spacing-triple;
	// overflow: hidden;
	

	.c-before-after__title {
		margin-bottom: $spacing-alt-double;
	}

	.c-before-after__intro {
		letter-spacing: 0.4px;
		margin-bottom: $spacing-alt-double;
		@include font-secondary--light;
	}
	

	.c-before-after__wrapper {
		// padding-top: $spacing-double;
		font-size: 0;
		.c-before-after__block{
			width: 100%;
			float:left;
			@media screen and (min-width: 500px){
				margin-right:20px;
				margin-left:20px;
				width: 45%;
			}
			@media screen and (min-width: 1250px){
				width: 46.7%;
			}
			@media screen and (max-width: 845px){
				// height:450px;
				width:100%;
				padding-left:10px;
				padding-right:10px;
				margin-top: $spacing-base-alt;

				&:first-of-type {
					margin-top: 0;
				}
			}
		}
	}
	
	.c-before-after__wrapper-overflow{
		overflow-x: scroll;
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: scrollbar;
		@media screen and (min-width: 500px){
			margin-left:-20px;
		}
		&::-webkit-scrollbar { 
    	opacity: 0;
		}
	}

	.c-before-after__block_wrapper{
		display: inline;
		@media screen and (max-width: 1100px){
			display: inline-block;
			margin-right: 40px;
			vertical-align: top;
		}

		@include responsive(tablet) {
			margin-right: 0;
		}
		&.slick-initialized {
			.slick-slide {

			}
		}
		& *{
			user-select: none;
		}
	}

	.scroll_notice{
		text-align: right;
		transition: color 300ms ease-in-out;
		cursor: pointer;

		span {
			border-bottom: 1px solid #fff;
		}

		i {
			margin-left: 5px;
		}

		&:hover {
			color: rgba(#fff, 0.75);
		}

	}

	.c-before-after__video {
		height: 300px;
		width: 100%;

		@include responsive(tablet) {
			height: 200px;
		}
		
	}

		.c-before-after__video-iframe {
			height: 100%;
			width: 100%;
		}

		.c-before-after__video-image {
			position: relative;
			display: block;
			height: 300px;
			width: 100%;
			background-color: $color-grey;
			@include cover;
			@include image(cover, center);

			@include responsive(tablet) {
				height: 200px;
			}

		}

		.c-before-after__video-icon {
			transition: background 300ms ease-in-out;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			height: 85px;
			width: 85px;
			background-color: $color-secondary;
			color: $color-grey;
			border-radius: 50%;

			i {
				text-align: center;
				top: 50%;
				transform: translateY(-50%);
				position: relative;
				width: 100%;
				font-size: 60px;
			}

			&:hover {
				background-color: darken($color-secondary, 15);
			}

		}


	.c-before-after__images {
		height: 100%;
		height: 300px;
		position: relative;

		@include responsive(tablet) {
			height: 200px;
		}

	}

		.c-before-after__image {
			// width: 50%;
			float: left;
			height: 100%;
			width: calc(#{50%} - #{5px});
			border-radius: 3px;
			background-color: $color-grey;
			@include image(cover, center);

			&:last-of-type {
				margin-left: 10px;
				background-color: darken($color-grey, 15);
			}

			&:first-of-type {
				margin-left: 0;
			}

			&.c-before-after__image--full {
				width: 100%;
			}

		}


 .c-before-after__block--alt {
		.c-before-after__block-title, .c-before-after__block-content {
				color: #fff;
		}
 }


	.c-before-after__block-title {
		color: $color-primary;
		margin-bottom: $spacing-base-alt;
		margin-top: $spacing-alt-double;

		&.c-before-after__block-title--large {
				margin-bottom: $spacing-alt-double;
		}

		& + .c-btn {
				margin-bottom: $spacing-alt-double;
		}
	}

	.c-before-after__block-content {
		margin-top: $spacing-base;
		margin-bottom: $spacing-base-alt;
		@include font-secondary--light;

		&.c-before-after__block-content--large {
				margin-bottom: $spacing-alt-double;
		}

		& + .c-btn {
				margin-bottom: $spacing-alt-double;
				font-size:12px;
		}
	}
	

	@include responsive(tablet) {
		padding-top: 0;
		padding-bottom: 0;
	}

}

.page-template-page-before-after-php .g-init{

	.c-before-after__block {
		height:auto!important;
	}

}