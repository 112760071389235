/* ———————————————————————————————————————— */
/* Components - Sub Nav
/* ———————————————————————————————————————— */

.c-sub-nav {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  padding-top: 80px;
  @include clearfix;
  z-index: 16;

  &:hover {
    .c-sub-nav__item {
        opacity: 0.45;
    }

    .c-sub-nav__advert {
        opacity: 1;
    }
  }

  .c-sub-nav__item {
    transition: opacity 300ms ease-in-out;
    position: relative;
    width: calc(20% - 16px);
    float: left;
    margin-right: 20px;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        opacity: 1;

        .c-grand-nav__item {
            opacity: 0.45;
        }
    }
    
  }  

    .c-sub-nav__link {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      @include font-primary--normal;
      font-weight: 600;
      pointer-events: none;
      opacity: 0;
      border-bottom: 1px solid #fff;
      width: 100%;
      display: inline-block;
      padding-bottom: 15px;
    }

  &.active {
    transition-delay: 200ms;
    opacity: 1;
    pointer-events: auto;

    .c-sub-nav__link {
      pointer-events: auto;
      opacity: 1;
    }

  }



  .c-sub-nav__advert {
    margin-bottom: 0;

    .c-sub-nav__advert-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: $spacing-base;
    }


    .c-sub-nav__advert-title {
        color: $color-primary;
        @include font-size(20px, 30px);
    }


  }


}



.c-sub-nav--alt {



    .c-sub-nav__item {
        width: 100%;
    }



        .c-sub-nav__link {
            display: inline-block;
            border-bottom: 0;
            padding-bottom: 25px;
        }



    .c-sub-nav__advert {
        display: none;
    }



}



.c-sub-nav--practices {
	.c-sub-nav__item {
		width: calc(25% - 20px);
		
		&:nth-last-of-type(-n+2) {
			margin-top: -25px;
		}
    }
}