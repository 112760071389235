.c-latest {
  margin-bottom: 125px;

  &.c-latest--mobile {
    display: none;
    margin-bottom: 0;
  }

  @include responsive(tablet) {
    display: none;

    &.c-latest--mobile {
      display: block;
    }

  }

}

.c-latest__curve {
  position: absolute;
  bottom: 100%;
  left: 0;
  fill: $color-secondary;
  transform: scale(1.05);

  &.c-latest__curve--grey {
      fill: $color-grey;
  }

  &.c-latest__curve--white {
      fill: #fff;
  }

  &.c-latest__curve--primary {
      fill: $color-primary;
  }

  &.c-latest__curve--secondary {
      fill: $color-secondary;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
      display: none;
  }
}

.c-latest-block--primary {
  .c-latest-title {
    color: #ffffff;
  }
}

.c-latest-block--secondary {
  .c-latest-title {
    color: $color-primary;
  }
}

.c-latest-block--white {
  .c-latest-title {
    color: $color-primary;
  }
}

.c-latest-block--grey {
  .c-latest-title {
    color: $color-primary;
  }
}