/* ———————————————————————————————————————— */
/* Components - Finance
/* ———————————————————————————————————————— */

.c-finance {
  background-color: #fff;
  padding: $spacing-base;
  top: 50%;
  transform: translateY(-50%);
  float: left;
  width: 100%;
  border-radius: 3px;



  .c-finance__close {
    transition: background 300ms ease-in-out;
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: $color-primary;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    color: #fff;

    i {
      top: 50%;
      transform: translateY(-50%);
      position: relative;
    }

    &:hover {
      background-color: darken($color-primary, 15);
    }

  }
  

  .c-finance__message {
    width: 50%;
    position: relative;
    color: $color-primary;
    float: left;
    display: block;
    @include font-primary--semi;
  }


  .c-finance__amount {
    font-size: 27px;
    background-color: $color-grey;
    text-align: right;
    float: left;
    display: block;
    position: relative;
    width: 100px;
    color: $color-primary;
    padding: 0;
    float: right;
    -webkit-appearance: none;
    -moz-appearance: none;
    @include font-primary--normal;
  }

  .c-finance__prefix {
    padding: 15px 20px;
    float: right;
    font-size: 30px;
    color: $color-primary;
    margin-bottom: $spacing-base;
    background-color: $color-grey;
    @include font-primary--normal;
  }


  .c-finance__block {
    padding: $spacing-base;
    background-color: #f7f7f7;
    border-radius: 3px;

    @include responsive(tablet) {
      margin-top: $spacing-base;
    }

  }

    .c-finance__block-title {
      color: $color-primary;
      margin-bottom: $spacing-alt-triple;
      @include font-primary--semi;
    }

    .c-finance__block-key{
      color: $color-text;
      @include font-secondary--semi;
    }

    .c-finance__block-list {
    }

      .c-finance__block-item {
        margin-bottom: $spacing-base;
        letter-spacing: 0.2px;
        @include font-secondary--light;
      }


  .c-finance__button {
    margin-top: $spacing-base;
  }
  

  @include responsive(tablet) {
    padding: $spacing-base;
    top: 100%;
  }

  @include responsive(mobile) {
    padding: $spacing-base 5px;
  }

}


.range-slider {
  display: inline-block;
  height: 30px;
  width: 100%;
  margin-bottom: $spacing-base;
}

.range-slider-inner {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
}

.range-slider-rail,
.range-slider-fill {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 10px;
  border-radius: 3px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.range-slider-rail {
  width: 100%;
  background-color: $color-grey;
}

.range-slider-fill {
  background-color: $color-primary;
}

.range-slider-knob {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: $color-primary;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}

.range-slider-hidden {
  display: none;
}




.c-finance-page {
    display: inline-block;
    width: 100%;


    .c-finance {
        top: 0;
        transform: none;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 50px;
        padding-top: 0;
    }


    .c-finance__close {
        display: none;
    }


}




.page-template-page-finance-php {


    .c-finance {
        margin-top: 50px;
    }


    .c-finance__title {
        display: none;
    }


}