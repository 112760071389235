/* ———————————————————————————————————————— */
/* Components - Comparison
/* ———————————————————————————————————————— */

.c-treatment-comparison {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-treatment-comparison__curve {
    fill: #fff;
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: scale(1.05); 

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


  .c-treatment-comparison__title {
    color: $color-secondary;
    margin-bottom: $spacing-alt-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }


  .c-treatment-comparison__block {
    background-color: $color-grey;
    padding: $spacing-double;
    border-radius: 3px;
    margin-bottom: $spacing-alt-double;
    background-color: #fff;

    @include responsive(tablet) {
      padding: $spacing-base;
    }

  }

    .c-treatment-comparison__block-title {
      color: $color-secondary;
      margin-bottom: 35px;
      @include font-primary--semi;
    }

    .c-treatment-comparison__block-list {
      list-style: disc;
      margin-left: 18px;
    }

    .c-treatment-comparison__block-item {
      margin-bottom: 10px;
      padding-left: $spacing-base-alt;
      @include font-secondary--normal;
      @include font-size(15px, 26px);

      &:last-of-type {
        margin-bottom: 0;
      }

    }


    .c-treatment-comparison__image {
      margin-top: 75px;
      width: 100%;
      height: auto;
      margin-bottom: 25px;

      @include responsive(mobile) {
        margin-top: 50px;
      }

    }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}