/* ———————————————————————————————————————— */
/* Components - Treatment Video
/* ———————————————————————————————————————— */

.c-treatment-video {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;

  .c-treatment-video__video {
    height: 300px;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;

    @include responsive(tablet) {
      height: 200px;
    }

  }

  .video {
    display: flex;
    align-items: center;
  }

  .c-treatment-video__video-iframe {
    height: 100%;
    width: 100%;
  }

  .c-treatment-video__video-image {
    position: relative;
    display: block;
    height: 300px;
    width: 100%;
    background-color: $color-grey;
    @include cover;
    @include image(cover, center);

    @include responsive(tablet) {
      height: 200px;
    }

  }

  .c-treatment-video__images {
    height: 100%;
    height: 300px;
    position: relative;

    @include responsive(tablet) {
      height: 200px;
    }

  }

  .c-treatment-video__block-title {
    margin-bottom: $spacing-base-alt;
    margin-top: $spacing-alt-double;

    &.c-treatment-video__block-title--large {
      margin-bottom: $spacing-alt-double;
    }

    & + .c-btn {
      margin-bottom: $spacing-alt-double;
    }
  }

  .c-treatment-video__block-content {
    margin-bottom: $spacing-base-alt;
    @include font-secondary--light;

    &.c-treatment-video__block-content--large {
      margin-bottom: $spacing-alt-double;
    }

    & + .c-btn {
      margin-bottom: $spacing-alt-double;
    }
  }


  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .c-treatment-video__video-icon {
    cursor: pointer;
    transition: background 300ms ease-in-out;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 85px;
    width: 85px;
    background-color: $color-secondary;
    color: $color-grey;
    border-radius: 50%;

    i {
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      width: 100%;
      font-size: 60px;
    }

    &:hover {
      background-color: darken($color-secondary, 15);
    }

  }
  

  .c-treatment-video__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: $color-secondary;
    transform: scale(1.05); 

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


  .c-treatment-video__content-text {
    max-width: 85%;
    font-family: $secondary-font;

    @include responsive(tablet) {
      max-width: 100%;
    }
  }

  .c-treatment-video__title {
    margin-bottom: $spacing-base;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }

  .c-treatment-video__title--white {
    color: #fff;
  }

  .c-treatment-video__description {
    color: black;
  }


  .c-treatment-video__quote {
    color: #fff;
    font-size: 50px;
    line-height: 60px;
    font-weight: 300;
    p {
      font-size: 30px;
      line-height: 40px;
      font-weight: 300;
    }
  }

  

  .c-treatment-video__block {
	width: 100%;
	position: relative;
	
	&::before {
		content: '';
		display: block;
		padding-top: 56.25%;
	}
  }


  iframe {
    width: 100%;
    height: 100%;
	position: absolute;
	top: 0;
	left: 0;
  }

  .c-treatment-video__reviews {
    max-height: 350px;
    pointer-events: none;
    background: #fff;
    margin: 0 -8px;
    padding-left: 35px;
  }

  .c-treatment-video__image {
    height: 350px;
    border-radius: 3px;
    float: left;
    height: 100%;
    width: calc(#{50%} - #{5px});
    background-color: $color-grey;
    @include cover;
    @include image(cover, center);

    &:last-of-type {
      margin-left: 10px;
      background-color: darken($color-grey, 15);
    }

    &.c-treatment-video__image--full {
      width: 100%;
    }

  }

  .c-treatment-video__image--no-left {

    &:last-of-type {
      margin-left: 0;
    }

  }

  .c-treatment-video__image-alt {
    margin-left: 0 !important;
  }


  .c-treatment-video__icon {
    cursor: pointer;
    transition: background 300ms ease-in-out;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 85px;
    width: 85px;
    background-color: $color-secondary;
    color: $color-grey;
    border-radius: 50%;

    i {
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      width: 100%;
      font-size: 60px;
    }

    &:hover {
      background-color: darken($color-secondary, 15);
    }

  }


  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;

    .g__item:last-of-type {
      margin-top: $spacing-base;
    }
  }

  .c-treatment-video__button {
    margin-top: $spacing-base;
  }

}


.c-treatment-video--primary {
  .c-treatment-video__curve {
    fill: $color-primary;
  }
  .c-treatment-video__title--reviews {
    color: #fff;
  }
  .c-treatment-video__content-text p {
    color: #fff;
  }
  .c-treatment-video__quote, .c-treatment-video__quote p {
    color: #fff;
  }
}

.c-treatment-video--secondary {
  .c-treatment-video__curve {
    fill: $color-secondary;
  }
  .c-treatment-video__title--reviews {
    color: $color-primary;
  }
  .c-treatment-video__content-text p {
    color: #fff;
  }
  .c-treatment-video__quote, .c-treatment-video__quote p {
    color: $color-primary;
  }
}

.c-treatment-video--white {
  .c-treatment-video__curve {
    fill: #fff;
  }
  .c-treatment-video__quote, .c-treatment-video__quote p {
    color: $color-primary;
  }

  .c-treatment-video__icon {
    background-color: $color-primary;
    color: #fff;
  }
}

.c-treatment-video--grey {
  .c-treatment-video__curve {
    fill: $color-grey;
  }
  .c-treatment-video__quote, .c-treatment-video__quote p {
    color: $color-primary;
  }
}

.c-treatment-video--grey, .c-treatment-video--white {
  .c-treatment-video__title--reviews {
    color: $color-secondary;
  }
}


.c-treatment-video__advert {
  width: 100%;
  height: auto;
}


.c-treatment-video__slider {
  .slick-dots {
    text-align: left;

    li {
      margin-left: 0;
      margin-right: 10px;
      width: 12px;
      height: 12px;
    }

    button {
      margin: 0;
      height: 12px;
      width: 12px;

      &::before {
        height: 12px;
        width: 12px;
        border: 1px solid $color-primary;
        border-radius: 50%;
        opacity: 1;
        color: transparent;
      }

    }

    .slick-active button::before {
      background-color: $color-primary;
      color: transparent;
    }
  }
}