/* ———————————————————————————————————————— */
/* Components - Treatment Slider
/* ———————————————————————————————————————— */

.c-treatment-slider {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-treatment-slider__curve {
    fill: $color-primary;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    transform: scale(1.05); 

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }
  

  .c-treatment-slider__title {
    color: #fff;
    margin-bottom: $spacing-alt-double;
  }

  .c-treatment-slide__button {
    margin-top: $spacing-double;
    padding: $spacing-half $spacing-double;
  }
  

  .slick-dots {
    position: relative;
    // margin-top: $spacing-alt-triple;
    text-align: left;

    li {
      width: 30px;
      height: 30px;
    }


    button {
      transition: opacity 300ms ease-in-out;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      font-size: 18px;
      color: $color-primary;
      opacity: 0.2;
      letter-spacing: 0.4;
      @include font-primary--bold

      &::before {
        display: none;
      }

      &:hover {
        opacity: 0.6;
      }

    }


    .slick-active button {
      opacity: 1;

      &:hover {
        opacity: 1;
      }

    }

    
    @include responsive(tablet) {
      margin-top: 0;
    }
  }
  

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}

.c-treatment-slider--primary {
  .c-treatment-slider__curve {
    fill: $color-primary;
  }
}

.c-treatment-slider--secondary {
  .c-treatment-slider__curve {
    fill: $color-secondary;
  }

  button {
    background-color: $color-secondary !important;
    color: #fff !important;
  }
}

.c-treatment-slider--white {
  .c-treatment-slider__curve {
    fill: #fff;
  }

  button {
    background-color: $color-primary !important;
    color: #fff !important;
  }

}

.c-treatment-slider--grey {
  .c-treatment-slider__curve {
    fill: $color-grey;
  }
}

.c-treatment-slider--grey, .c-treatment-slider--white {

  .c-treatment-slider__title {
    color: $color-primary;
  }

  .c-treatment-slide {

    .c-treatment-slide__title {
      color: $color-primary;
    }

    .c-treatment-slide__content {
      color: $color-primary;
    }


    .c-treatment-slide__count {
      background-color: $color-primary;
      color: #fff;
    }

    .count-last {
      &::after {
        background: $color-primary;
      }
    }

  }
}