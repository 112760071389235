/* ———————————————————————————————————————— */
/* Components - Clinic Filters
/* ———————————————————————————————————————— */

.c-clinic-filters {
    float: right;
    margin-top: 50px;



    .c-clinic-filters__title {
        color: #fff;
        text-transform: uppercase;
        margin-right: 20px;
        display: inline-block;
    }

    

    select {
        -webkit-appearance: none;
        appearance: none;
        -moz-appearance: none;
        border-radius: 3px;
        border: 0;
        padding: 15px;
        padding-right: 50px;
        color: #fff;
        background-color: $color-primary;
        text-transform: uppercase;
        font-weight: 700;
        outline: none;

        &:focus {
            outline: none;
        }

        @include responsive(mobile) {
            width: 100%;
        }
    }



    .c-clinic-filter {
        display: inline-block;
        position: relative;
        margin-right: $spacing-base;

        &::before {
            content: "\f078";
            font-family: FontAwesome;
            position: absolute;
            right: 15px;
            top: 15px;
            color: #fff;
            pointer-events: none;
        }

        &:last-of-type {
            margin-right: 0;
        }

        @include responsive(mobile) {
            margin-right: 0;
            margin-bottom: $spacing-base;
            width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }


    @include responsive(tablet) {
        width: 100%;
        display: inline-block;
    }
}