/* ———————————————————————————————————————— */
/* Components - Faqs
/* ———————————————————————————————————————— */

.c-faqs {
    // padding-top: $spacing-alt-double;
    padding-bottom: $spacing-triple;


    .c-faqs__title {
        color: #fff;
        margin-bottom: $spacing-alt-double;
    }


    .c-faqs__curve {
        position: absolute;
        bottom: 100%;
        fill: $color-primary;
        color: #fff;
        transform: scale(1.05);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }  
    }


    @include responsive(tablet) {
        padding-top: 0;
        padding-bottom: 0;
    }
}