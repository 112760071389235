/* ———————————————————————————————————————— */
/* Components - Team
/* ———————————————————————————————————————— */

.c-team {
  margin-top: $spacing-alt-double;
  

  .c-team__image {
    width: 100%;
    height: 250px;
    background-color: $color-grey;
    border-radius: 3px;
    margin-bottom: 35px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  
  .c-team__title {
    margin-bottom: $spacing-base-alt;
  }


  .c-team__about {
    color: #fff;
    @include font-secondary--light;
  }


  .c-team__button {
    width: 100%;
    text-align: center;
  }


  &:nth-of-type(-n+4) {
    margin-top: 0;
  }
  
  
  @include responsive(tablet) {
    margin-top: $spacing-alt-double !important;
    
    &:nth-of-type(-n+2) {
      margin-top: 0 !important;
    }

  }


  @include responsive(mobile) {
    margin-top: $spacing-alt-double !important;
    
    &:first-of-type {
      margin-top: 0 !important;
    }

    &:nth-of-type(2) {
      margin-top: $spacing-alt-double !important;
    }

  }


}