/* ———————————————————————————————————————— */
/* JD - Base Input
/* ———————————————————————————————————————— */

input, textarea {
  outline: 0;
  border: 0;
  border-radius: 3px;
  padding: $spacing-half $spacing-base-alt;

  &::placeholder {
    text-transform: uppercase;
  }
}

.list-select-wrap .nf-field-element::after {
  content: "\E313";
  font-family: 'Material Icons';
  position: absolute;
  right: $spacing-base-alt;
  top: 50%;
  color: $color-primary;
  transform: translateY(-50%);
}


.mailchimp-optin-wrap {
  flex-wrap: nowrap !important;
  align-items: center !important;
  justify-content: flex-start !important;

  .nf-field-label {
    display: none !important;
  }

  .nf-field-description, .nf-field-element {
    width: auto !important;
  }

  .nf-field-description {
    margin-left: 10px;
  }

}


.o-row--primary, .o-row--secondary, .c-enquiry-popup {
  .mailchimp-optin-wrap .nf-field-description p {
    color: #fff !important;
  }
}


.ninja-hiden {
	display: none !important;

	&.isActive {
		display: block !important; 
	}
}