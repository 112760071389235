/* ———————————————————————————————————————— */
/* Components - Glossary
/* ———————————————————————————————————————— */

.c-glossary.c-glossary {
    padding-top: $spacing-half;
    padding-bottom: $spacing-base;



    .c-glossary__curve {
        fill: $color-primary;
        position: absolute;
        bottom: 100%;
        left: 0;
        transform: scale(1.05);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }
    
    
    
    .c-glossary__key {
        margin-top: $spacing-base;

        @include responsive(tablet) {
            display: none;
        }
    }
        
    
        .c-glossary__item {
            transition: background 300ms ease-in-out border 300ms ease-in-out color 300ms ease-in-out;
            width: 39px;
            height: 39px;
            font-size: 22px;
            text-align: center;
            padding: 8px 0;
            background-color: $color-primary;
            color: #fff;
            border: 1px solid #fff;

            &:hover {
                border: 1px solid #fff;
                background-color: #fff;
                color: $color-primary;
            }

            &.active {
                background-color: #fff;
                color: $color-primary;

                &:hover {
                    background-color: darken(#fff, 25);
                    border: 1px solid darken(#fff, 25);
                    color: #fff;
                }

            }

        }



    .c-glossary__key-mobile {
        display: none;
        position: relative;

        &::before {
            content: "\f078";
            font-family: FontAwesome;
            position: absolute;
            right: 15px;
            top: 15px;
            color: $color-primary;
            pointer-events: none;
        }

        @include responsive(tablet) {
            display: inline-block;
        }

        @include responsive(mobile) {
            width: 100%;
        }
    }


        .c-glossary__select {
            -webkit-appearance: none;
            appearance: none;
            -moz-appearance: none;
            border-radius: 3px;
            border: 0;
            padding: 15px;
            padding-right: 50px;
            color: $color-primary;
            min-width: 250px;
            background-color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            outline: none;

            &:focus {
                outline: none;
            }

            @include responsive(mobile) {
                width: 100%;
            }
        }


    
    .c-glossary__main {
        margin-top: $spacing-double;
    }
    
    

    .c-glossary__section {
        display: none;

        &.active {
            display: block;
        }

    }



    @include responsive(tablet) {
        padding-top: 0;
    }
    

}