/* ———————————————————————————————————————— */
/* Components - Mobile Nav
/* ———————————————————————————————————————— */

.c-mobile-nav {
    position: relative;
    left: 0;
    top: 0;


    li {
        background: $color-primary;
        width: 100%;
        float: left;
        padding: 20px;
        border-bottom: 1px solid darken($color-primary, 10);

        &.-no-children
        {
            padding: 0px;
            height: 62px;
            a
            {
                padding: 20px;
                height: 100%;
                width: 100%;
                float: left;
            }
        }

        a {
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
        }

        i
        {
            float: right;
            color: #fff;
        }
    }
    
    > li
    {
        ul
        {
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        display: none;
        .js-back
        {
            color: #fff;
        }
        }
    }

    &__outer
    {
        display: block;
    }

    .c-mobile-sub-nav__item
    {
        background: $color-secondary!important;
    }

    .c-mobile-grand-nav__item
    {
        background: $color-primary--dark!important;
    }

}


.c-mobile-nav-wrapper {
    display: none;
    position: absolute;
    top: 110px;
    float: left;
    width: 100%;
    z-index: 22;
}
.c-mobile-nav-wrapper.fixed{
    position: fixed;
    top:64px;
}

.c-mobile-nav__link--none {
    pointer-events: none;
}