.c-team-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
}


    .c-team-popup__container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .c-team-popup__inner {
        background-color: #fff;
        padding: 50px;
        width: 80%;
        border-radius: 2px;
        position: relative;
        z-index: 100;
        max-height: 75vh;
        overflow: auto;
        @include responsive(mobile) {
            max-height: 600px;
        }
    }

        .c-team-popup__title {
            color: $color-secondary;
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .c-team-popup__specialist-title {
            color: $color-secondary;
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 600; 
        }

        .c-team-popup__job {
            margin-bottom: 10px;
            font-weight: 600;
        }

        .c-team-popup__image {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }

        .c-team-popup__abilities {

            a {
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }

    .c-team-popup__close {
        position: absolute;
        top: -40px;
        right: -40px;
        color: #fff;
        z-index: 100;
        font-size: 22px;
    }