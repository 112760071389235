/* ———————————————————————————————————————— */
/* Object - Row
/* ———————————————————————————————————————— */

/*--------------
 Main Row
--------------*/

.o-row {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  outline: 0;
}




/*--------------
 Primary bg
--------------*/

.o-row--primary {
  background-color: $color-primary;
}




/*--------------
 Secondary bg
--------------*/

.o-row--secondary {
  background-color: $color-secondary;

  .c-btn--secondary.ninja-forms-field {
    background-color: $color-primary;
    color: #fff;

    &:hover {
      opacity: 1;
      background-color: darken($color-primary, 15);
    }

  }

}





/*--------------
 Dark bg
--------------*/

.o-row--dark {
  background-color: $color-secondary--dark;
}





/*--------------
 Grey bg
--------------*/

.o-row--grey {
  background-color: $color-grey;
}





/*--------------
 Image bg, Cover
--------------*/

.o-row--image-cover {
 @include image(cover); 
}