/* ———————————————————————————————————————— */
/* Components - Buttons
/* ———————————————————————————————————————— */

.c-btn {
  display: inline-block;
  cursor: pointer;
  padding: $spacing-half $spacing-base;
  transition: background $transition-time ease-in-out, opacity $transition-time ease-in-out;
  border-radius: $border-radius;
  background: #fff;
  color: $color-primary;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  @include font-primary--semi;

  &:hover {
    opacity: 0.8;
  }
  

  @include responsive(mobile) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  &.c-btn--large {
    padding-left: $spacing-double;
    padding-right: $spacing-double;
  }
  

  &.c-btn--primary {
    background-color: $color-primary;
    color: #fff;

    &:hover {
      opacity: 1;
      background-color: darken($color-primary, 15);
    }
    
  }


  &.c-btn--secondary {
    background-color: $color-secondary;
    color: #fff;

    &:hover {
      opacity: 1;
      background-color: darken($color-secondary, 15);
    }
    
  }


  &.c-btn--disabled {
    pointer-events: none;
    opacity: 0.6;

    &:hover {
        background-color: $color-primary;
    }
  }


  &.c-btn--margin-top {
    margin-top: 40px;
  }

  &.text-secondary {
    color: $color-secondary;
  }


}
