/* ———————————————————————————————————————— */
/* Components - Enquiry Popup
/* ———————————————————————————————————————— */

.c-contact-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    background-color: rgba(#000, 0.4);
  
  
    .o-container {
      height: 100%;
      position: relative;
    }
    
  
    .c-contact-popup__wrapper {
      padding: $spacing-double;
      background-color: $color-primary;
      top: 50%;
      transform: translateY(-50%);
      overflow: auto;
      max-height: 90%;
      position: relative;
  
      @include responsive(tablet) {
        padding: $spacing-base;
      }
  
      @include responsive(mobile) {
        padding: $spacing-half;
      }
  
    }
  
  
    .c-contact-popup__close {
      position: absolute;
      top: $spacing-base;
      right: $spacing-base;
      font-size: 24px;
      color: #fff;
      z-index: 2;
  
      @include responsive(mobile) {
        top: $spacing-half;
        right: $spacing-half;
      }
  
    }

    
    .c-contact-popup__title {
      color: #fff;
    }


    .c-contact-popup__key {
      position: relative;
    }

      .c-contact-popup__button {
        position: relative;
        margin-top: $spacing-base;
        text-align: center;
      }

    

    .c-contact-popup__clinics {
      display: none;
    }

      .c-contact-popup__item {
        display: none;
      }


      .c-contact-popup__back {
        color: #fff;
        font-size: 22px;
        margin-bottom: 15px;
        cursor: pointer;

        i {
          margin-right: 10px;
        }

      }
      
      .c-contact-popup__clinic {
        margin-top: 15px;

        a {
          font-size: 22px;
          line-height: 1.5;
          color: #fff;

          &:hover {
            color: rgba(#fff, 0.75);
          }

        }

      }
  
}