/* ———————————————————————————————————————— */
/* Components - Home
/* ———————————————————————————————————————— */

.c-home {
  // padding-top: $spacing-layout/2;


  .c-home__intro {
    margin-bottom: $spacing-triple;
    @include font-primary--light;

    @include responsive(tablet) {
      margin-bottom: $spacing-alt-double;
    }
  }


  .c-home__treatments {

    &.c-home__treatments--mobile {
      display: none;
      margin-bottom: 0;
    }
    
    // @include responsive(mobile) {
    //   display: none;

    //   &.c-home__treatments--mobile {
    //     display: block;
    //   }

    // }

  }


  
  .c-home__latest {
    margin-bottom: 125px;

    &.c-home__latest--mobile {
      display: none;
      margin-bottom: 0;
    }

    @include responsive(tablet) {
      display: none;

      &.c-home__latest--mobile {
        display: block;
      }

    }

  }

    .c-home__latest-title {
      margin-bottom: $spacing-double;

      @include responsive(tablet) {
        margin-bottom: $spacing-base;
      }

      &.c-home__latest-title--alt {
        color: #fff;
      }

       &.c-home__latest-title--secondary {
        color: $color-secondary;
      }

    }


  .c-home__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: $color-secondary;
    transform: scale(1.05);

    &.c-home__curve--grey {
        fill: $color-grey;
    }

    &.c-home__curve--white {
        fill: #fff;
    }

    &.c-home__curve--primary {
        fill: $color-primary;
    }

    &.c-home__curve--secondary {
        fill: $color-secondary;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


  .c-home__slider {
    margin-bottom: 0;
    // margin-top: $spacing-alt-double;

    @include responsive(tablet) {

      &.js-numbers {
        margin-top: 0;
      }

    }

  }


  .c-home__slide {

    @include responsive(tablet) {
      margin-top: $spacing-base-alt;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

    .c-home__slide-top {
      color: #fff;
      @include font-size(30px);
      margin-bottom: 0;
      font-weight: 600;
    }

    .c-home__slide-number {
      color: #fff;
      margin-bottom: 0;
      @include font-size(80px, 80px);
      @include font-primary--bold;

      @include responsive(tablet) {
        @include font-size(58px);
      } 

    }
    
    .c-home__slide-fact {
      color: $color-secondary;
      margin-bottom: 0;
      @include font-size(20px);
    }

    .c-home__slide-lower {
      color: #fff;
      margin-bottom: 10px;
      @include font-size(30px);
    }

  .slick-dots {
    text-align: left;

    li {
      margin-left: 0;
      margin-right: 10px;
      width: 12px;
      height: 12px;
    }

    button {
      margin: 0;
      height: 12px;
      width: 12px;

      &::before {
        height: 12px;
        width: 12px;
        border: 1px solid #fff;
        border-radius: 50%;
        opacity: 1;
        color: transparent;
      }

    }

    .slick-active button::before {
      background-color: #fff;
      color: transparent;
    }

  }





  .c-home__map {
    padding-bottom: $spacing-triple;

    .c-home__map-main {
      width: 100%;
      height: 600px;
      background-color: $color-grey;
      border-radius: 3px;
      margin-bottom: 30px;

      @include responsive(tablet) {
        height: 450px;
      }

    }

    @include responsive(tablet) {
      padding: $spacing-alt-double 0;
      padding-bottom: 0;
    }

  }
  




  .c-home__clinic {
    position: relative;

    @include responsive(mobile) {
      margin-top: $spacing-base;

      &:first-of-type {
        margin-top: 0;
      }
      
    }

  }

    .c-home__clinic-title {
      color: $color-primary;
      letter-spacing: 0.2px;
      margin-bottom: $spacing-base-alt;
      @include font-primary--semi;
    }

    .c-home__clinic-address {
      color: #fff;
      margin-bottom: round($spacing-quarter / 2);
      @include font-secondary--light;

      &:last-of-type {
        margin-bottom: 0;
      }

    }



  @include responsive(tablet) {
    padding-top: 0;
  }

}



.c-home__list {
    margin-top: 10px;
    list-style-type: none;
    display: inline-block;
    float: right;
    position: relative;
    z-index: 2;
    color: #fff;


    &:hover {
        .c-home__list-item {
            opacity: 0.3;
        }
    }


    .c-home__list-item {
        transition: opacity 300ms ease-in-out;
        cursor: pointer;
        margin: 0;
        display: inline-block;
        padding: 0 25px;
        border-left: 1px solid #fff;
        font-weight: 300;
        @include font-size(20px, 30px);

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }

        &.active {
            opacity: 1;
            color: $color-primary;

            &:hover {
                opacity: 1;
            }

        }

        &:hover {
            opacity: 1;
        }

        @include responsive(tablet) {
            border-left: 0;
            width: 100%;
            padding: 0;
            margin-left: 0;
        }

    }


    @include responsive(laptop) {
        width: 100%;
        margin-bottom: $spacing-double;
    }

}


.c-home__clinics-title {
    float: left;
}