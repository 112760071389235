/* ———————————————————————————————————————— */
/* Tools - Images
/* ———————————————————————————————————————— */

// Image mixin for background images can take multiple parameters,
// background-size, background-position, and background-repeat.
// The only default is the repeat parameter which defaults 
// to no-repeat as this is the most common. 
// Example: 
// @include image(cover, center);

@mixin image($size, $position: none, $repeat: no-repeat) {
  background-size: $size;
  background-repeat: $repeat;

  @if $position != none {
    background-position: $position;
  }

}