/* ———————————————————————————————————————— */
/* Components - Load More
/* ———————————————————————————————————————— */

.c-load-more {
  margin-top: $spacing-alt-double;

  a {
    padding-left: $spacing-double;
    padding-right: $spacing-double;
  }

}