/* ———————————————————————————————————————— */
/* Components - Pricing Row
/* ———————————————————————————————————————— */

.c-pricing-row {
    // padding-top: $spacing-alt-double;
    display: block;
    padding-bottom: $spacing-triple;

    @include responsive(tablet) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include responsive(mobile) {
        display: none;
    }


    .c-pricing-row__curve {
        position: absolute;
        bottom: 100%;
        left: 0;
        fill: #fff;
        transform: scale(1.05); 

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }
   
}


    .c-pricing-row___title {
        margin-bottom: $spacing-base-alt;
    }


    .c-pricing-row__intro {
        color: $color-grey--light;
        margin-bottom: $spacing-double;
    }




    .c-pricing-row__item {
        background-color: $color-primary;
        color: #fff;
        padding: 40px;
        border-radius: 4px;
        margin-top: $spacing-base-alt;

        &:nth-of-type(-n+3) {
            margin-top: 0;
        }

        @include responsive(tablet) {
            margin-top: $spacing-base-alt !important;
            &:first-of-type {
                margin-top: 0 !important;
            }
        }
    }



        .c-pricing-row__top {
            color: #fff;
            margin-bottom: 5px;
            font-weight: 300;
            @include font-size(20px, 22px);
        }


        .c-pricing-row__price {
            color: #fff;
            margin-bottom: 15px;
            display: block;
            position: relative;
            @include font-size(40px, 46px);
        }


        .c-pricing-row__main {
            color: #fff;
            font-family: $secondary-font;
            font-weight: 300;
        }


        .c-pricing-row__button {
            width: 100%;
            text-align: center;
        }



.c-pricing-row--mobile {
    display: none;

    @include responsive(mobile) {
        display: block;
    }


    .slick-dots {
        margin-top: $spacing-base;
        position: relative;
        bottom: unset;
        text-align: left;

        button {
            display: none;
        }
        
        li {
            border-radius: 4px;
            display: inline-block;
            margin-right: 5px;
            width: calc((100%/4) - 4px);
            height: 3px;
            background-color: #D8D8D8;
            overflow: hidden;
            transition: background 300ms ease-in-out;

            &.slick-active {
                background-color: $color-primary;
            }
        }
    }
}


    .c-pricing-row__swipe {
        text-align: center;
        color: $color-primary;
        margin: $spacing-half 0;
        font-size: 18px;
        display: block;
        position: relative;
    }