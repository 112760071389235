/* ———————————————————————————————————————— */
/* Components - Home Banner
/* ———————————————————————————————————————— */

.c-home-banner {
  background-color: $color-primary;
  overflow: hidden;
  height: 650px;
  position: relative;

  @include responsive(tablet) {
    height: 550px;
  }

  .c-home-banner__slider {
    overflow: hidden;

    .slick-dots {
      position: absolute;
      bottom: 150px;
      left: 0;
      display: block;
      width: 100%;
      z-index: 8;
      text-align: left;
      max-width: $container-width;
      padding: 0 $container-padding;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 10;

      @include responsive(tablet) {
        bottom: 30px;
      }

      li {
        border-radius: 4px;
        display: inline-block;
        margin-right: 5px;
        width: 90px;
        height: 3px;
        background-color: #fff;
        overflow: hidden;
        transition: background 300ms ease-in-out;

        button {
          display: none;
        }

        &.slick-active {
          background-color: $color-primary;
        }
      }

    }

  }


  .c-home-banner__item {
    background-color: $color-primary;
    height: 650px;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    float: left;
    position: relative;
    display: none;
    @include image(cover, center);


    &:first-of-type {
      display: block;
    }

    &.not-active {

      .c-home-banner__content {
        left: 115%;
      }

      .c-home-banner__bubble--main {
        left: 150%;
      }

      .c-home-banner__bubble--small {
        left: 145%;
      }

      .c-home-banner__bubble--image {
        left: 0;
      }
    }

    &.active, .reset {

      .c-home-banner__content {
        left: -140%;
      }

      .c-home-banner__bubble--main {
        left: -65%;
      }

      .c-home-banner__bubble--small {
        left: -10%;
      }

      .c-home-banner__bubble--image {
        // transition: left 2s ease-in-out;
        left: 0;
      }

    }

    @include responsive(tablet) {
      height: 550px;
    }

  }

  .c-home-banner__bubble {
    transition: left 1s;
    position: absolute;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    transform: translateY(-50%) scale(4, 4);
    top: 50%;
    overflow: hidden;

    @media (min-width: 1500px) {
      transform: translateY(-50%) scale(5, 5);
    }

    @media (min-width: 2350px) {
      transform: translateY(-50%) scale(6, 6 );
    }

  }


  .c-home-banner__bubble--main {
    transform: translateY(-50%) scale(5.2, 5.2);
    left: -5%;
    top: 30%;
    opacity: 0.35;
    z-index: 2;
    background-color: $color-primary;

    @media (min-width: 1500px) {
      transform: translateY(-50%) scale(6.5, 6.5);
    }

    @media (min-width: 1900px) {
      transform: translateY(-50%) scale(7.5, 7.5);
    }

    @media (min-width: 2100px) {
      transform: translateY(-50%) scale(8.2, 8.2);
    }

    @media (min-width: 2200px) {
      transform: translateY(-50%) scale(8.7, 8.7 );
    }

    @media (min-width: 2350px) {
      transform: translateY(-50%) scale(9.5, 9.5 );
    }

    @include responsive(tablet) {
      transform: translateY(20%) scale(3.5, 3.5 );
    }

    @include responsive(mobile) {
      transform: translateY(-10%) scale(1.8, 1.8 );
    }

  }



  .c-home-banner__bubble--image {
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 1;
    transform: none;
    @include image(cover, center);

    // @include responsive(laptop) {
    //   display: none;
    // }

  }
    
    .c-home-banner__darken {
      background-color: rgba(#000, 0.2);
      @include cover;
    }


  .c-home-banner__bubble--small {
    background-color: #fff;
    opacity: 0.1;
    width: 250px;
    height: 250px;
    top: 60%;
    left: 55%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 3;
  }

  .o-container {
    height: 100%;
  }
  

  .c-home-banner__content {
    transition: left 1s ease-in-out;
    position: absolute;
    left: 0;
    padding: 0 40px;
    display: inline-block;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);
    z-index: 5;

    @include responsive(tablet) {
      padding: 0 20px;
      top: 55%;
    }

  }

    .c-home-banner__title {
      color: #fff;
      letter-spacing: 0.6px;
      max-width: 55%;
      font-size: 50px;
      line-height: 58px;

      @include responsive(laptop) {
        max-width: 100%;
      }

      @include responsive(mobile) {
        @include font-size(35px, 42px);
      }

    }
    

    .c-home-banner__subtitle {
      max-width: 55%;
      color: #fff;
      @include font-size(25px, 35px);

      @include responsive(laptop) {
        max-width: 100%;
      }

      @include responsive(mobile) {
        @include font-size(15px, 24px);
      }

    }


    .c-home-banner__button {
        margin-right: $spacing-base;

        &:last-child {
            margin-right: 0;
        }

        @include responsive(mobile) {
            margin-top: $spacing-base;

            &:first-child {
                margin-top: 0;
            }

        }
    }

  


  
  
  .c-home-banner__nav-wrapper {
    position: absolute;
    bottom: 200px;
    left: 0;
    display: block;
    width: 100%;
    z-index: 8;

    @include responsive(tablet) {
      bottom: 30px;
    }

  }

    .c-home-banner__nav {
      border-radius: 4px;
      display: inline-block;
      margin-right: 5px;
      width: 90px;
      height: 3px;
      background-color: #fff;
      // background-color: darken($color-primary, 15);
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0;
        background-color: $color-primary;
        width: 100%;
        height: 100%;
      }

      &.active::before {
        transition: right 4.9s linear;
        right: 0;
      }

      @include responsive(mobile) {
        width: 50px;
      }

    }






  .c-home-banner__curve {
    fill: #fff;
    width: 100%;
    z-index: 8;
    position: absolute;
    bottom: 0;
    transform: scale(1.05);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


}