/* ———————————————————————————————————————— */
/* Object - List
/* ———————————————————————————————————————— */

/*--------------
 Main List
--------------*/

.o-list {
  list-style-type: none;
  margin-bottom: 0;
}

  //List Item
  .o-list__item {
    margin-left: 0;
  }





/*--------------
 Inline List
--------------*/

.o-list--inline {

  .o-list__item {
    display: inline-block;
  }

}