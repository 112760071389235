/* ———————————————————————————————————————— */
/* Components - Treatment
/* ———————————————————————————————————————— */

.c-treatment {
  margin-top: $spacing-base-alt;

  .c-treatment__image {
    height: 200px;
    border-radius: $border-radius;
    background-color: $color-primary;
    margin-bottom: $spacing-alt-double;
    z-index: 2;
    @include image(cover, center);
  }

    .c-treatment__logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #fff;
      height: 50px;
      width: 175px;
      padding: 10px;
  }

    .c-treatment__media {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
    }

  .c-treatment__content {
    li {
      line-height: 26px;
    }
  }


  .c-treatment__language {
    span:last-of-type  {
      display: none;
    }
  }



  .c-treatment__image--circle {
    border-radius: 100%;
    max-width: 107px;
    max-height: 107px;
    height: 200px;
    background-color: $color-primary;
    margin-bottom: $spacing-alt-double;
    z-index: 2;
    @include image(cover, center);
  }

  .js-treatment-video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .c-treatment__title {
    color: $color-primary;
    margin-bottom: $spacing-base;
    @include font-primary--semi;
    &.small {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .c-treatment__alt-title {
    margin-bottom: $spacing-half;
  }

  .c-treatment__intro {
    margin-bottom: $spacing-base;
  }

  .c-treatment__video {
    position: relative;
    width: 100%;
    height: 250px;
    margin-bottom: $spacing-alt-double;
    z-index: 1;
  }

  .c-treatment__video-icon {
    pointer-events: none;
    z-index: 3;
    position: absolute;
    top: calc(50% - 25px);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 42px;
    color: #fff;
    background-color: $color-secondary;
    border-radius: 50%;
    padding: 10px;
  }

  .c-treatment__button {
    width: 100%;
    text-align: center;
  }


  @include responsive(tablet) {
    margin-top: $spacing-base !important;

    &:nth-of-type(-n+2) {
      margin-top: 0 !important;
    }
  }

  @include responsive(mobile) {
    margin-top: $spacing-base !important;

    &:nth-of-type(4) {
         margin-top: $spacing-base !important;
    }

    &:nth-of-type(3) {
         margin-top: $spacing-base !important;
    }

    &:nth-of-type(2) {
        margin-top: $spacing-base !important;
    }

    &:first-of-type {
      margin-top: 0 !important;
    }
  }
  

  &:nth-of-type(-n+4) {
    margin-top: 0;
  }   


  &.c-treatment--alt {
    margin-top: 40px !important;

    .c-treatment__button {
        height: 100%;
    }

    .c-treatment__title {
      color: #fff;
    }
  

    &:nth-of-type(-n+3) {
        margin-top: 0 !important;
    } 


    @include responsive(tablet) {
        margin-top: 40px !important;

        &:nth-of-type(3) {
            margin-top: 40px !important;
        }

        &:nth-of-type(-n+2) {
            margin-top: 0 !important;
        }
    }

    @include responsive(mobile) {
        margin-top: $spacing-base !important;

        &:nth-of-type(3) {
            margin-top: 40px !important;
        }

        &:nth-of-type(2) {
            margin-top: 40px !important;
        }

        &:first-of-type {
            margin-top: 0 !important;
        }
    }

  }



  &.c-treatment--alt {
    .c-treatment__image {
        height: 250px;
    }
  }


}

  .c-treatment-prices__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: #fff;
    transform: scale(1.05); 

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


.c-treatment-blocks--white {
  .c-treatment__title {
    color: $color-primary;
    &.grey {
      color: $color-grey--dark;
    }
  }
}

.c-treatment-blocks--primary {
  .c-treatment__title {
    color: #ffffff;
  }
  .c-treatment__content p {
    color: #ffffff;
  }
}

.c-treatment-blocks--secondary {
  .c-treatment__title {
    color: #fff;
  }
  .c-treatment__content p {
    color: #ffffff;
  }
}

.c-treatment-blocks--grey {
  .c-treatment__title {
    color: $color-primary;
  }
}



.c-treatment--white-intro {
  .c-treatment__intro {
    color: #fff;
  }
}




.c-treatment--grid {
  padding: $spacing-half;
  margin-top: 0 !important;
  position: relative;
  display: block;
}


.js-grid-items {
  width: calc(100% + 25px);
  margin: 0 -$spacing-half;
}