/* ———————————————————————————————————————— */
/* Tools - Font Size
/* ———————————————————————————————————————— */

// The global mixin for creating font size and line height,
// can auto generate line height from global settings or
// a line height can be passed in. Example:
// @include font-size(24px, 36px);

@mixin font-size($font-size, $line-height: auto) {
  font-size: $font-size;

  @if $line-height == auto {
    line-height: ceil($font-size / $global-line-height) * ($global-line-height / $font-size);
  }

  @else {
    line-height: $line-height;
  }

}