/* ———————————————————————————————————————— */
/* Components - Clinic Team
/* ———————————————————————————————————————— */

.c-clinic-team {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-clinic-team__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: $color-secondary;
    transform: scale(1.05);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


  .c-clinic-team__title {
    color: #fff;
    margin-bottom: $spacing-triple;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }
  
  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: $spacing-base;
  }

  &.c-clinic-team--white {
    .c-clinic-team__title {
      color: $color-primary;
    }
    .c-clinic-team__curve {
      fill: #ffffff;
    }
    .c-team__title {
      color: $color-text;
    }
  }

  &.c-clinic-team--primary {
    .c-clinic-team__title {
      color: #ffffff;
    }
    .c-clinic-team__curve {
      fill: $color-primary;
    }
    .c-team__title {
      color: #ffffff;
    }
    .c-team p {
      color: #ffffff;
    }
  }

  &.c-clinic-team--secondary {
    .c-clinic-team__title {
      color: $color-primary;
    }
    .c-clinic-team__curve {
      fill: $color-secondary;
    }
    .c-team__title {
      color: $color-primary;
    }
    .c-team p {
      color: #ffffff;
    }
  }

  &.c-clinic-team--grey {
    .c-clinic-team__title {
      color: $color-primary;
    }
    .c-clinic-team__curve {
      fill: $color-grey;
    }
    .c-team__title {
      color: $color-text;
    }
  }

  .c-clinic-team--abilities {
    display: none;
  }

}