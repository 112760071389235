/* ———————————————————————————————————————— */
/* Components - Advert
/* ———————————————————————————————————————— */

.c-advert {
    margin-top: $spacing-base;
    


    .c-advert__image {
        width: 100%;
        margin-bottom: $spacing-base;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
    }



    .c-advert__title {
        color: $color-primary;
        font-weight: 600;
        @include font-size(20px, 30px);
    }



    .c-advert__button {
    }



}