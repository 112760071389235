/* ———————————————————————————————————————— */
/* Colors
/* ———————————————————————————————————————— */

/*
  Global color palette, set up project colors within this
  file then call the variables across the site. 
  Ideally, all the colors should be set in the global
  section at the top of the settings and then the
  global brand colors referenced for links and headings etc.
*/


// Global Brand Colors
$color-primary: #4bbbbd;
$color-secondary: #40395f;
$color-primary--dark: #1ab3b0;
$color-secondary--dark: #272439;
$color-primary--light: #50BBBC;
$color-grey: #f7f7f7;
$color-grey--dark: #656566;
$color-grey--light: #4A4A4A;



// Page
$color-page-background: #fff;
$color-page-color: #474747;





// Links
$color-links: $color-primary;





// Text
$color-text: $color-page-color;





// Headings
$color-heading: $color-primary;
$color-heading--alt: #fff;