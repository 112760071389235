/* ———————————————————————————————————————— */
/* Components - Header Search
/* ———————————————————————————————————————— */

.c-header-search {
  height: 160px;
  z-index: 25;
  position: relative;

  .c-header-search__form {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

    .c-header-search__input {
      background-color: transparent;
      border: 0;
      color: #fff;
      letter-spacing: 0.8px;
      width: 400px;
      @include font-size(25px);
      line-height: 1.5;
      @include font-primary--light;

      &::placeholder {
        color: rgba(#fff, 0.6);
        text-transform: none;
      }

      @include responsive(mobile) {
        padding-left: 0;
        width: 250px;
      }
  
    }

    .c-header-search__button {
      transition: color 300ms ease-in-out;
      background-color: transparent;
      border: 0;
      padding: 0;
      padding-top: 5px;
      color: #fff;
      outline: 0;

      &:hover {
        color: darken(#fff, 15);
      }

      &:active, &:focus {
        outline: 0;
      }

    }


    @include responsive(laptop) {
        height: 140px;
    }

}