 /* ———————————————————————————————————————— */
/* Components - Benefit
/* ———————————————————————————————————————— */

.c-benefit {
    margin-top: $spacing-double;



    .c-benefit__title {
        color: $color-primary;
    }



    .c-benefit__text {
        font-weight: 300;
	}

    

    .c-benefit__button {
        text-align: center;
        width: 100%;
    }


    .c-benefit__logo {
        width: 80px;
        height: 80px;
        background-color: $color-primary;
        background-size: auto 36px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 100%;
        overflow: hidden;
        margin-bottom: $spacing-base;
    }



    &:nth-of-type(-n+4) {
        margin-top: 0;
    }



    @include responsive(tablet) {
        margin-top: $spacing-double !important;

         &:nth-of-type(-n+2) {
            margin-top: 0 !important;
        }
    }



    @include responsive(mobile) {
        margin-top: $spacing-double !important;

         &:first-of-type {
            margin-top: 0 !important;
        }

        &:nth-of-type(2) {
            margin-top: $spacing-double !important;
        }
    }



}