/* ———————————————————————————————————————— */
/* Components - Grid Blocks
/* ———————————————————————————————————————— */

.c-grid-blocks {

}



.c-grid-blocks--mobile {
    display: block;

    @include responsive(mobile) {
        display: block;
    }


    .slick-dots {
        display: none;
        position: relative;
        bottom: unset;
        text-align: left;

        button {
            display: none;
        }
        
        li {
            border-radius: 4px;
            display: inline-block;
            margin-right: 5px;
            width: calc((100%/4) - 4px);
            height: 3px;
            background-color: #D8D8D8;
            overflow: hidden;
            transition: background 300ms ease-in-out;

            &.slick-active {
                background-color: $color-primary;
            }
        }
    }
}


    .c-grid-blocks__swipe {
        text-align: center;
        color: $color-primary;
        margin: $spacing-half 0;
        font-size: 18px;
        display: none;
        position: relative;
        transition: color 300ms ease-in-out;
        cursor: pointer;

        @include responsive(tablet) {
            display: block;
        }

        &:hover {
            color: $color-primary--dark;
        }
    }


    .c-grid-blocks__swipe--desktop {
        display: block;
        text-align: right;
        text-decoration: underline;
        font-size: 14px;
        z-index: 10;
    }



    .c-grid-blocks__swipe--bottom {
        margin-top: 0;
    }


    .c-grid-blocks__swipe--top {
        margin-bottom: 0;
    }