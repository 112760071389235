.accepting_nhs_patients{
	background: $color-secondary;
	color: #fff;
	.o-container{
		display: flex;
		align-items: center;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.left{
		width: 50%;
		font-size: 1.5rem;
	}
	.right{
		margin-right: 0;
		margin-left: auto;
		img{
			max-width: 120px;
			display: inline;
			vertical-align: middle;
		}
		.enquire_button{
			background: #fff;
			color: $color-secondary;
			padding: 15px 25px;
			display: inline;
			vertical-align: middle;
			margin-left: 1rem;
			border-radius: 5px;
			font-weight: 600; 
			display: inline-block;
		}
	}
	@media screen and (max-width: 690px){
		.o-container{
			padding: 15px 20px;
		}
		.left{
			width: auto;
			font-size: 1.25rem;
		}
		.right{
			font-size: 0.65rem;
		}
	}
	@media screen and (max-width: 570px){
		.right{
			img{
				max-width: 100px;
			}
			.enquire_button{
				padding: 15px;
			}
		}
	}
	@media screen and (max-width: 540px){
		.o-container{
			flex-wrap: wrap;
			.left{
				width: 100%;
				text-align: center;
			}
			.right{
				margin: 0 auto;
				margin-top: 15px;
			}
		}
	}
}

.accepts_nhs_patients{
	display: flex;
	align-items: center;
	margin-top: 30px;
	background: $color-secondary;
	color: #fff;
	padding: 15px;
	p{
		display: inline;
		width: auto;
		margin: 0;
		color: #fff;
	}
	.img_container{
		width: 60px;
		height: 24px;
		margin-left: auto;
		img{
			width: 100%;
		}
	}
	@media screen and (max-width: 1048px){
		p{
			width: calc(100% - 75px);
		}
		.img_container{
			margin-left: auto;
			margin-right: 0;
		}
	}
}