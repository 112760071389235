/* ———————————————————————————————————————— */
/* Components - Nav
/* ———————————————————————————————————————— */

.c-nav {
  z-index: 20;

  // David's Amend
  height: 50px;
  
  width: 100%;
  @include clearfix;

  &:hover {
    
    .c-nav__item { 
        opacity: 0.45;
    }

  }

  .c-nav__item {
    transition: opacity 300ms ease-in-out;
    margin-left: $spacing-half;
    height: 100%;
    float: left;

    // David's Amend
    padding-right: 10px;
    padding-left: 10px;

    &:first-of-type {

        margin-left: 0px;

        // David's Amend
        padding-left: 0px;
    }

    &:last-of-type {
        float: right;

        // David's Amend
        padding-right: 0px;
    }

    &:hover {
        opacity: 1;
    }

  }

    .c-nav__link {
      -webkit-backface-visibility: hidden;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      @include font-primary--semi;
      @include font-size(14px);
    }

    span {
      float: left;

      i {
        top: 100%;
        position: relative;
        display: block;
      }

    }


  @include responsive(laptop) {
    display: none;
  }


}