.c-treatment-menu {
    background-color: $color-secondary;
    position: relative;
    padding: 15px 0;
    width: 100%;

    &.fixed {
        position: fixed;
        top: 120px;
        left: 0;
        z-index: 999999;

        @include responsive(tablet) {
            top: 65px
        }
    }

    @include responsive(laptop) {
        display: none;
    }
}


    .c-treatment-menu__list {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
    }


    .c-treatment-menu__item {
        margin-left: 0;
        margin-bottom: 0;
    }


    .c-treatment-menu__link {
        color: #fff;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: darken(#fff, 15);
        }

    }




.c-treatment-menu--mobile {
    display: none;
    padding: 0;

    @include responsive(laptop) {
        display: block;
    }


    .c-treatment-menu__trigger {
        padding: 15px $spacing-base;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.isActive {
            .c-treatment-menu__icon {
                transform: rotate(-180deg);
            }
        }
    }


        .c-treatment-menu__text {
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
            @include font-size(16px, 18px);
        }


        .c-treatment-menu__icon {
            transition: transform 300ms ease-in-out;
            color: #fff;
            @include font-size(16px, 18px);
        }



    .c-treatment-menu__list {
        display: none;
        position: relative;
        width: 100%;
        overflow-y: scroll; 
        position: relative;
        height: 368px;
    }


        .c-treatment-menu__item {
            width: 100%;
            display: block;
            padding: $spacing-half $spacing-base;
            border-bottom: 1px solid #fff;

            &:first-of-type {
                border-top: 1px solid #fff;
            }
        }
}