/* ———————————————————————————————————————— */
/* Components - Careers
/* ———————————————————————————————————————— */

.c-careers {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;

  .c-careers__load-more {
    margin-top: $spacing-quad;

    @include responsive(tablet) {
      margin-top: $spacing-alt-double;
    }

  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}