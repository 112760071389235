/* ———————————————————————————————————————— */
/* Components - Lower Footer
/* ———————————————————————————————————————— */

.c-lower-footer {
  display: inline-block;
  width: 100%;
  margin-bottom: $spacing-half;

  .c-lower-footer__details {
    float: left;
    color: rgba(#fff, 0.4);
    @include font-secondary--light;

    @include responsive(tablet) {
      width: 100%;
      margin-bottom: $spacing-half;
    }

  }
  
  .c-lower-footer__menu {
    float: right;

    @include responsive(tablet) {
      width: 100%;
    }

  }

    .c-lower-footer__item {
    }

    .c-lower-footer__link {
      color: rgba(#fff, 0.4);
      @include font-secondary--light;

      &:hover {
        color: rgba(#fff, 1);
      }

    }
    .c-lower-footer__link-text {
      color: rgba(#fff, 0.4);
      @include font-secondary--light;
    }

  @include responsive(tablet) {
    margin-bottom: 0;
  }

}