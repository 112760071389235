.private-treatments{
	&.o-row--secondary{
		p,div,strong,b,i{
			color: #fff;
		}
	}
	.dropdowns{
		margin-bottom: 5rem;
		max-width: 950px;
	}
	.dropdowns .dropdown:first-child .category_title{
		border-top: 1px solid $color-primary; 
	}
	.category_title{
		border-bottom: 1px solid $color-primary;
		padding: 2.5rem 0;
		font-weight: 600;
		font-size: 0;
		span{
			display: inline-block;
			&.title{
				font-size: 1.75rem;
				width: 95%;
				margin-right: 1rem;
				color: $color-text;
			}
			&.arrow{
				font-size: 1.25rem;
				width: calc(5% - 1rem);
				text-align: right;
				transition: 0.2s;
			}
		}

	}
	.shower{
		display: none;
		&:checked{
			& ~ .items{
				max-height: 100%;
				padding: 1rem 0;
				border-bottom: 1px solid $color-primary;
			}
			& ~ label .category_title{
				border-bottom: 0;
				.arrow{
					transform: rotateX(180deg);
				}
			}
		}
	}
	.items{
		max-height: 0;
		overflow: hidden;
		.spacer{
			height: 2rem;
		}
		.row{
			display: flex;
			flex-wrap: wrap;
			.item{
				width: 50%;
				color: $color-primary;
				line-height: 1.75rem;
    			font-size: 1.2rem;
				&:nth-of-type(2n){
					color: $color-text;
					text-align: right;
				}
				&.label{
					font-weight: 600;
					margin-bottom:0.2rem;
				}
			}
		}
	}

}