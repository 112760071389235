/* ———————————————————————————————————————— */
/* Components - Search Item
/* ———————————————————————————————————————— */

.c-seach-item {
  margin-top: $spacing-base;

  .c-seach-item__image {
    height: 200px;
    width: 100%;
    border-radius: 3px;
    background-color: rgba($color-secondary, 0.2);
    @include image(cover, center);
  }

  .c-seach-item__title {
    color: $color-primary;
    letter-spacing: 0.4px;
    margin-top: $spacing-base;
    @include font-primary--semi;
  }

  
  &:nth-of-type(-n+4) {
    margin-top: 0;
  }

  @include responsive(tablet) {
    margin-top: $spacing-base !important;

    &:nth-of-type(-n+2) {
      margin-top: 0 !important;
    }

  }

  @include responsive(mobile) {
    margin-top: $spacing-base !important;

    &:nth-of-type(-n+1) {
      margin-top: 0 !important;
    }

  }

}