/* ———————————————————————————————————————— */
/* Components - Glossary Top
/* ———————————————————————————————————————— */

.c-glossary-top {
    padding-top: $spacing-base;
    padding-bottom: $spacing-base;


    h3,h4,h5,h6 {
        color: $color-primary;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    @include responsive(tablet) {
        padding-top: 0;
    }
}