/* ———————————————————————————————————————— */
/* Components - Single Post
/* ———————————————————————————————————————— */

.c-single-post {
  // padding-top: $spacing-alt-double;
  
  .c-single-post__content {
    
    img {
      width: 100%;
      height: auto;
    }

    h1,h2,h3,h4,h5 {
      color: $color-primary;
    }

    p {
      @include font-secondary--light;
    }

    li {
      @include font-secondary--light;
      margin-bottom: $spacing-half;
      line-height: 26px;
    }

    input, textarea {
        color: $color-text;
        border: 1px solid $color-text;
    }

    strong {
        font-weight: 600;
	}
	
	select {
		border: 1px solid $color-grey--dark;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: 0;
		border-radius: 3px;
		padding: $spacing-half $spacing-base-alt;
		margin-right: $spacing-half;
		color: #a3a3a3;
		background-color: #fff;
	}

  }


  .c-single-post__related {
    padding-top: $spacing-quad;
    padding-bottom: $spacing-triple;

    @include responsive(tablet) {
      padding-top: 45px;
      padding-bottom: 0;
    }

  }

    .c-single-post__related-title {
      margin-bottom: $spacing-double;

      @include responsive(tablet) {
        margin-bottom: $spacing-base;
      }

    }


  @include responsive(tablet) {
    padding-top: 0;
  }


}




.author-text {
	margin-bottom: 75px;

	@include responsive(mobile) {
		margin-bottom: 35px;
	}
}