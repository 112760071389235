/* ———————————————————————————————————————— */
/* Components - Treatment Slide
/* ———————————————————————————————————————— */

.c-treatment-slide {
  position: relative;
  display: block;
  outline: none;


  .c-treatment-slide__title {
    color: #fff;
    max-width: 50%;
    @include font-size(28px);
    margin-bottom: $spacing-half;
    line-height: 35px;

    @include responsive(tablet) {
      @include font-size(30px);
    }
  }

  .c-treatment-slide__content {
    color: #fff;
    max-width: 90%;
    @include font-size(15px);
    @include font-secondary--light;
  }


  .c-treatment-slide__count {
    background-color: #fff;
    display: inline-block;
    width: 100px;
    height: 100px;
    color: $color-primary;
    border-radius: 50%;
    position: static;
    margin-bottom: $spacing-base;
    @include font-size(45px);

    .c-treatment-slide__number {
      text-align: center;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      position: relative;
      letter-spacing: 0.4;
      @include font-primary--bold
    }

  }

  .count-last {

    &::after {
      content: '';
      width: calc(100% - 180px);
      height: 5px;
      background: #fff;
      position: absolute;
      right: 40px;
    }

  }

  // &:nth-last-of-type(3) {
    
  //   .c-treatment-slide__count::after {
  //     display: none;
  //   }
    
  // }

}