/* ———————————————————————————————————————— */
/* Components - Buttons
/* ———————————————————————————————————————— */

.c-about-clinics {
  padding-bottom: $spacing-triple;


  .c-about-clinics__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: $color-secondary;
    transform: scale(1.05);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
    
  }


  .c-about-clinics__title {
    outline: 0;
    letter-spacing: 0.4px;
    margin-bottom: $spacing-base;
    color: #fff;
    display: inline-block;

    @include responsive(tablet) {
      margin-top: 0;
      margin-bottom: $spacing-base;
    }

  }


  .c-about-clinics__items {
    margin-top: $spacing-base;
    width: 100%;
    display: inline-block;


    .c-accordions__title {
        transition: color 300ms ease-in-out;
        font-weight: 300;
        @include font-size(30px, 40px);
    }

    .c-accordions__dropdown i {
        transition: color 300ms ease-in-out;
    }

    .c-accordions__dropdown:hover {
        .c-accordions__title, i {
            color: $color-primary;
        }
    }


  }

  @include responsive(tablet) {
    padding-bottom: 0;
  }

}