/* ———————————————————————————————————————— */
/* Components - Clinic Intro
/* ———————————————————————————————————————— */

.c-clinic-intro {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-double;
  display: none;


  .c-clinic-intro__title {
    margin-bottom: $spacing-alt-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }

    h3, h4, h5, h6 {
        color: $color-primary;
    }

  strong {
    font-weight: 600;
  }


  .c-clinic-intro__text, p {
    margin-bottom: 0;
    @include font-secondary--light;
  }


  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: $spacing-base;
  }


  &.active {
    display: block;
  }

  &.c-clinic-intro--sidebar {
    padding-bottom: 0;
  }

}