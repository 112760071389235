/* ———————————————————————————————————————— */
/* Fonts
/* ———————————————————————————————————————— */


/*--------------
  Include fonts
--------------*/
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,600');





/*------------
  Fonts
------------*/

$primary-font: 'canada-type-gibson', sans-serif;
$secondary-font: 'Work Sans', sans-serif;

  


  
/*------------
  Font Weight
------------*/

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi: 600;
$font-bold: 700;





/*------------
  Font Sizes
------------*/

$h1-heading-size: 60px !default;
$h2-heading-size: 45px !default;
$h3-heading-size: 45px !default;
$h4-heading-size: 30px !default;
$h5-heading-size: 20px !default;
$h6-heading-size: 15px !default;





/*------------
  Line Height
------------*/

$h1-line-height: 70px !default;
$h2-line-height: 55px !default;
$h3-line-height: 55px !default;
$h4-line-height: 40px !default;
$h5-line-height: 30px !default;
$h6-line-height: 26px !default;