/* ———————————————————————————————————————— */
/* Object - Footer
/* ———————————————————————————————————————— */

.o-footer {
	z-index: 2;
	position: relative;

	@media screen and (max-width: 425px){
		.o-footer__logo{
			padding-bottom: 1rem;
		}
	}

	.o-footer__curve {
		fill: $color-secondary--dark;
		position: absolute;
		bottom: 100%;
		transform: scale(1.05); 

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
				display: none;
		}
	}
	

	.o-footer__wrapper {
		padding-top: $spacing-double;

		@include responsive(tablet) {
			padding-top: 0;
		}
	}

	.g__item {

		@include responsive(mobile-tablet) {
			margin-top: $spacing-alt-double;

			&:first-of-type {
				margin-top: 0;
			}
			
		}

	}

	.g-mobile-12{
		@include responsive(mobile-tablet) {
			flex-basis: calc(100% - 40px);
		}
	}


	.o-footer__logo {
		width: 100px;
		fill: #fff;
		margin-bottom: $spacing-double;
		position: relative;
		display: inline-block;
		
		@include responsive(tablet) {
				margin-bottom: 35px;
		}

		@include responsive(mobile) {
				margin-bottom: 0;
		}

	}

	
	.o-footer__list {
		&:hover {
			.o-footer__item {
				opacity: 0.3;
			}
		}

		&.o-footer__images {
				&:hover {
					.o-footer__item {
						opacity: 0.3 !important;
					}
				}
				.o-footer__item:hover {
					 opacity: 1 !important; 
				}
		}

		.g__item {
			margin-top: 0;
		}
	}


	.o-footer__list--inline {
		display: flex;

		.o-footer__item {
			margin-right: $spacing-half;

			&:last-of-type {
				margin-right: 0;
			}
			
		}

	}


	.o-footer__item {
		transition: opacity 300ms ease-in-out;
		margin-bottom: $spacing-half;

		&.o-footer__item--first {
			margin-bottom: $spacing-base;
			opacity: 1 !important;

			.o-footer__link {
				@include font-primary--semi;

				&:hover {
					color: #fff;
				}

			}

		}

		&:hover {
				opacity: 1 !important;
		}

	}

		.o-footer__link {
			letter-spacing: 0.2px;
			text-transform: uppercase;
			color: #fff;
		}


	.o-footer__button {
		display: inline-block;
		width: calc(50% - 20px);
		text-align: center;
		font-size: 0.85rem;
		padding: 15px;
		&.second{
			margin-left: 40px;
		}
		@media screen and (max-width: 1168px){
			width: calc(50% - 10px);
			&.second{
				margin-left: 20px;
			}
		}
		@media screen and (max-width: 1168px){
			width: 100%;
			max-width: 250px;
			display: block;
			&.second{
				margin-left: 0;
				margin-top: 1rem;
			}
		}
		@media screen and (max-width: 568px){
			max-width: 100%;
		}
	}
	

}

.footer-button-wrapper{
	font-size: 0;
}
		
.o-footer__image-item {
		float: left;
		margin-right: $spacing-base;

		&:first-of-type {
				.o-footer__image {
						height: 40px;
				}
		}

		&:last-of-type {
				margin-right: 0;
				text-align: right;
		}

}


.o-footer__image {
		width: auto;
		height: 30px;
		max-width: 100px;
		display: inline-block;
		vertical-align: top;
		margin-left: 3rem;
		margin-bottom: 1.5rem;
		img{
			width: 100%;
		}
		@media screen and (max-width: 930px){
				display:block;
				margin-left:0;
				padding-bottom: 3rem;
		}
}