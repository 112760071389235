/* ———————————————————————————————————————— */
/* Components - Event Post
/* ———————————————————————————————————————— */

.c-event-post {
  height: 400px !important;
  background-color: $color-secondary;
  margin-top: $spacing-alt-double;

  .c-event-post__image {
    width: 100%;
    height: 100%;
    padding: $spacing-double;
    @include image(cover, center);
  }

  .c-event-post__overlay {
    background-color: rgba($color-secondary, 0.6);
    @include cover;
  }

  .c-event-post__title {
    position: relative;
    color: #fff;
    z-index: 2;
    padding: 0 $spacing-double;
    padding-top: $spacing-double;
    letter-spacing: 0.3px;
    @include font-primary--semi;

    @include responsive(mobile) {
      padding: 0 $spacing-base;
      padding-top: 45px;
    }

  }

  .c-event-post__intro {
    position: relative;
    color: #fff;
    z-index: 2;
    margin-bottom: 0;
    padding: 0 $spacing-double;

    @include responsive(mobile) {
      padding: 0 $spacing-base;
    }

  }

  .c-event-post__author {
    position: absolute;
    bottom: $spacing-double;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    z-index: 2;

    span {
      @include font-primary--semi;
    }

    @include responsive(tablet) {
      bottom: 45px;
    }

  }
  
  
  .c-event-post__hover {
    transition: top 300ms ease-in-out;
    z-index: 8;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: $color-secondary;
    overflow: hidden;

    .c-event-post__author { 
      left: $spacing-double;

      @include responsive(tablet) {
        left: $spacing-base;
        margin-bottom: 0;
      }

    }

    @include responsive(tablet) {
      top: 0;
    }

  }

    .c-event-post__bubble {
      display: inline-block;
      background-color: #fff;
      opacity: 0.1;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

      .c-event-post__bubble--top {
        top: 40%;
        transform: scale(5, 5) translateY(-50%);
      }

      .c-event-post__bubble--small {
        top: $spacing-base;
        left: 50%;
        transform: translateX(-50%);
      }

      .c-event-post__bubble--main {
        left: 70%;
        top: 170%;
        transform: scale(7, 7) translateY(-50%);
      }


    .c-event-post__hover-title {
      position: relative;
      top: 50%;
      z-index: 2;
      transform: translateY(-50%);
    }


  &:hover .c-event-post__hover {
    top: 0;
  }


  &:first-of-type, &:nth-of-type(2) {
    margin-top: 0;
  }

  @include responsive(tablet) {
    margin-top: $spacing-base;

    &:first-of-type {
      margin-top: 0;
    }

    &:nth-of-type(2) {
      margin-top: $spacing-base;
    }

  } 

}


.js-latest {
  .c-event-post {
    @include responsive(mobile) {
      margin-top: 0 !important;
    }
  }
}