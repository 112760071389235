/* ———————————————————————————————————————— */
/* Components - Single Service
/* ———————————————————————————————————————— */

.c-single-service {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-quad;

  
  .c-single-service__intro {
  }

    .c-single-service__title {
      margin-bottom: $spacing-alt-double;
      max-width: 70%;

      @include responsive(tablet) {
        max-width: 100%;
      }

      @include responsive(tablet) {
        margin-bottom: $spacing-base;
      }

    }

    .c-single-service__text {
      max-width: 60%;
      @include font-secondary--light;

        h3, h4, h5, h6 {
            color: $color-primary;
        }

        ul {
            margin-bottom: $spacing-base;
        }

        li {
            margin-bottom: 5px;
            line-height: 26px;
            
            &:last-of-type {
                margin-bottom: 0;
            } 
        }

      @include responsive(tablet) {
        max-width: 100%;
      }

    }


  .c-single-service__wrapper {
    margin-top: $spacing-alt-triple;
  }
  

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}