/* ———————————————————————————————————————— */
/* Global Settings
/* ———————————————————————————————————————— */


  /*---------------
  Globals
  ---------------*/

  $global-font-size: 15px !default;
  $global-line-height: 26px !default;
  $global-backgroiund: $color-page-background !default;
  $global-font-weight: $font-normal !default;
  $global-text-color: $color-page-color !default;
  $global-font-family: $primary-font !default;





  /*------------
  Breakpoints
  ------------*/

  // MOBILE
  $max-w__mobile: 560px;

  //Mid-way Betweeb
  $max-w__mobile-tablet: 570px;

  // TABLET
  $max-w__tablet: 768px;

  // LAPTOP
  $max-w__laptop: 1024px;

  // LARGE
  $max-w__large: 1280px;





  /*------------
  Spacing
  ------------*/

  $spacing-base: 30px;
  $spacing-half: round($spacing-base/2);
  $spacing-quarter: round($spacing-base/4);
  $spacing-double: round($spacing-base*2);
  $spacing-triple: round($spacing-base*3);
  $spacing-quad: round($spacing-base*4);
  $spacing-layout: round($spacing-base*5);


  $spacing-base-alt: 25px;
  $spacing-alt-half: round($spacing-base-alt/2);
  $spacing-alt-quarter: round($spacing-base-alt/2);
  $spacing-alt-double: round($spacing-base-alt*2);
  $spacing-alt-triple: round($spacing-base-alt*3);
  $spacing-alt-quad: round($spacing-base-alt*4);
  $spacing-alt-layout: round($spacing-base-alt*6);





  /*------------
  Grid Gutter
  ------------*/

  $gutter: 40px !default;
  $gutter--large: 40px !default;





  /*------------
  Container
  ------------*/

  $container-width: 1280px;
  $container-padding: $spacing-base;
  $container-padding--small: $spacing-double;
  $container-padding--large: $spacing-layout/2;
  $container-padding--extra-large: $container-padding--large*2;





  /*-------------
  Other Settings
  -------------*/

  $transition-time: 300ms;
  $transition-time--slower: 500ms;


  $border-radius: 3px;
  $border-radius--large: round($border-radius*2);
  $border-radius--huge: round($border-radius*4);
