/* ———————————————————————————————————————— */
/* Components - Enquiry Popup
/* ———————————————————————————————————————— */

.c-enquiry-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(#000, 0.4);


  .o-container {
    height: 100%;
    position: relative;
  }
  

  .c-enquiry-popup__wrapper {
    padding: $spacing-double;
    background-color: $color-primary;
    top: 50%;
    transform: translateY(-50%);

    @include responsive(tablet) {
      padding: $spacing-base;
    }

    @include responsive(mobile) {
      padding: $spacing-half;
    }

  }


  .c-enquiry-popup__close {
    position: absolute;
    top: $spacing-base;
    right: $spacing-base;
    font-size: 24px;
    color: #fff;

    @include responsive(mobile) {
      top: $spacing-half;
      right: $spacing-half;
    }

  }

  .nf-field-container {
    @include responsive(mobile) {
      margin-bottom: 15px !important;
    }
  }

}