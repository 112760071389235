/* ———————————————————————————————————————— */
/* Components - Stats
/* ———————————————————————————————————————— */

.c-stats {
    padding-bottom: $spacing-double;
    


    .c-stats__curve {
        position: absolute;
        bottom: 100%;
        left: 0;
        fill: $color-primary;
        transform: scale(1.05);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }



}