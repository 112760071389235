/* ———————————————————————————————————————— */
/* Components - Search Results
/* ———————————————————————————————————————— */

.c-search-result {
  padding-top: $spacing-triple; 
  padding-bottom: $spacing-quad; 

  .c-search-result__item {
    margin-top: $spacing-quad;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .c-search-result__title {
    margin-bottom: $spacing-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }
  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}