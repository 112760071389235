/* ———————————————————————————————————————— */
/* Components - Clinic
/* ———————————————————————————————————————— */

.c-clinic {
  margin-top: $spacing-alt-double;


  .c-clinic__map {
    width: 100%;
    height: 250px;
    border-radius: 3px;
    margin-bottom: $spacing-alt-double;
    @include image(cover,center);
    z-index: 3;
  }


  .c-clinic__google-map {
    position: absolute;
    width: 100%;
    height: 250px;
    left: 0;
    top: 0;
    z-index: 2;
    border-radius: 3px;

    &.c-clinic__google-map--above {
        z-index: 4;
    }
  }
  

  .c-clinic__title {
    letter-spacing: 0.4px;
    @include font-primary--semi;
    &-green{
      color: $color-primary;
    }
  }


  .c-clinic__intro {
    color: #fff;
    @include font-secondary--light;
  }

  .c-clinic__content {
    margin-top: 30px;
  }


  .c-clinic__button {
    width: 100%;
    text-align: center;
  }


  @include responsive(tablet) {
    margin-top: $spacing-alt-double !important;
  } 


  @include responsive(mobile) {
    margin-top: $spacing-alt-double !important;
  } 


}

.c-clinic__opening-times {
  h1, h2, h3, h4, h5 {
    color: $color-primary;
    line-height: 30px;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
  }
  p {
    color: $color-text;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 26px;
    font-weight: 300;
    strong {
      font-weight: bold;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.c-clinic__directions {
  h1, h2, h3, h4, h5 {
    color: $color-primary;
    line-height: 30px;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
  }
  p, span {
    color: $color-text;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 26px;
    font-weight: 300;
    margin-bottom: 0;
    strong {
      font-weight: bold;
    }
  }
}


.c-clinic-nav {

    @include responsive(tablet) {
      display: none;
    }
  .c-clinic-nav__left {
    display: inline-block;
    width: 80%;
  }
  .c-clinic-nav__right {
    display: inline-block;
    text-align: right;
    width: 19%;
    a {
      text-align: center;
    }
  }
  .c-clinic-nav__label, .c-clinic-nav__link {
    color: #ffffff;
    text-transform: uppercase;
    margin-right: 20px;
    font-weight: 400;
    @include responsive(laptop) {
      font-size: 14px;
    }
  }
  .c-clinic-nav__label, .c-clinic-nav__link {
    display: inline-block;
    margin-bottom: 5px;
  }

  &.fixed {
    position: fixed;
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.25);
    top: 0;
    z-index: 3;
  }
}