/* ———————————————————————————————————————— */
/* Components - Price
/* ———————————————————————————————————————— */

.c-prices {
  padding: $spacing-alt-double 0;
  

  &.c-prices--lower {
    padding-bottom: $spacing-base;
  }

  
  .c-prices__title {
    margin-bottom: $spacing-alt-double;

     @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }

    
  .c-prices__intro-text {
    max-width: 45%;
    margin-bottom: $spacing-triple;
    @include font-secondary--light;

    @include responsive(tablet) {
      max-width: 100%;
      margin-bottom: $spacing-alt-double;
    }

  }


  .c-prices__intro--button {
    .c-prices__intro-text { 
        margin-bottom: $spacing-base;
    }
  }


  .c-prices__button {
    margin-bottom: $spacing-triple;

    @include responsive(tablet) {
        margin-bottom: $spacing-base;
    }
  }


  @include responsive(tablet) {
    padding: 0;
  }


}



.c-prices--text {
    padding-bottom: $spacing-base-alt;

    @include responsive(tablet) {
        padding: 0;
    }    
    
}