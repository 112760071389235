.c-sticky-reviews {
    background-color: #fff;
    border-radius: 3px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999999999999999999999;
    padding-top: 5px;
    padding-left: 7px;
    padding-right: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);

    @include responsive(tablet) {
        display: none;
    }
    
}



    .c-sticky-reviews__loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-primary;
        z-index: 2;
        border-radius: 3px;
    }



    .c-sticky-reviews__iframe {
        z-index: 4;
        position: relative;
    }