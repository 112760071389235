/* ———————————————————————————————————————— */
/* Components - Share
/* ———————————————————————————————————————— */

.c-share {


  .c-share__title {
    color: $color-primary;
    text-transform: uppercase;
  }

    .c-share__title--alt {
      color: $color-secondary;
    }

  
  .c-share__list {

  }

    .c-share__item {
      margin-bottom: $spacing-base-alt;
      width: 100%;
    }

      .c-share__link {
        width: 100%;
        text-align: center;
      }


}