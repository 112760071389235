/* ———————————————————————————————————————— */
/* Components - Referral
/* ———————————————————————————————————————— */

.c-referral {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-referral__intro {
    margin-bottom: $spacing-alt-triple;
  }

  
  .c-referral__title {
    color: $color-secondary;
    margin-bottom: $spacing-alt-double;
  }


  input, textarea {
    background: $color-grey;
    color: $color-text;

    &::placeholder {
      color: $color-text;
      opacity: 0.7;
    }
  
  }


  #nf-field-58 {
    height: 265px;
  }

  textarea {
    resize: none;
  }

  input[type="button"] {
    @include responsive(mobile) {
        width: 100% !important;
    }
  }


  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    border: 0;
    border-radius: 3px;
    padding: $spacing-half $spacing-base-alt;
    color: rgba($color-text, 0.7);
  }

  
  .nf-field-label {
    margin-bottom: 0 !important;
  }


  ul {

    li {
      width: 35%;
      float: left;
    }

  }
  
  
  .nf-field-element  p {
    max-width: 40% !important;
    margin: $spacing-alt-double 0;
  }


  .nf-field-label label {
    color: $color-primary !important;
    margin-top: $spacing-triple !important;
    margin-bottom: $spacing-alt-double !important;
    font-weight: 400 !important;
    display: block;
    position: relative;
    text-transform: uppercase;

  }


  .nf-form-fields-required, .ninja-forms-req-symbol, .nf-error-msg {
    display: none;
  }


}



.c-referral--alt {

    .c-referral__title {
        color: #fff;
    }


    .c-referral__intro {
        color: #fff;
    }

    .nf-field-element  p {
        color: #fff !important;
    }
}