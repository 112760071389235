/* ———————————————————————————————————————— */
/* Object - Container
/* ———————————————————————————————————————— */

/*--------------
 Main Container
--------------*/

.o-container {
  max-width: $container-width;
  margin: 0 auto;
  padding: $container-padding $gutter;
  width: 100%;

  @include responsive(tablet) {
    padding: $container-padding 20px;
  }

}





/*--------------
 No Padding
--------------*/

.o-container--none {

  padding: 0 $gutter;

  @include responsive(tablet) {
    padding: 0 20px;
  }

}


.o-container--half {

  padding: calc(#{$container-padding} / 2) $gutter;

  @include responsive(tablet) {
    padding: 0 20px;
  }

}


/*--------------
 Small Padding
--------------*/

.o-container--small {
  padding: $container-padding--small $gutter;

  @include responsive(tablet) {
    padding: $container-padding--small 20px;
  }

}





/*--------------
 Large Padding
--------------*/

.o-container--large {
  padding: $container-padding--large $gutter;
  &.small_top{
    padding-top: 10px;
  }
  &.small_bottom{
    padding-bottom: 10px;
  }

  @include responsive(tablet) {
    padding: $spacing-alt-double 20px;
  }
}

/*--------------
 Extra Large Padding
--------------*/

.o-container--extra-large {
  padding: $container-padding--extra-large $gutter;
  &.small_top{
    padding-top: 10px;
  }
  &.small_bottom{
    padding-bottom: 10px;
  }

  @include responsive(tablet) {
    padding: $spacing-alt-quad 20px;
  }
}

/*--------------
 Large Padding
--------------*/

.o-container--large-half {
  padding: $container-padding--large $gutter;

  @include responsive(tablet) {
    padding: $spacing-alt-double 20px;
    padding-bottom: $spacing-base-alt;
  }
}




 .o-container--mobile-top {

    @include responsive(tablet) {
      padding-top: 0;
    }

  }
