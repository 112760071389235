/* ———————————————————————————————————————— */
/* Components - Accordions
/* ———————————————————————————————————————— */

.c-accordions {
    border-top: 1px solid $color-primary;
    width: 100%;
    border-radius: 2px;
      
    &.c-accordions--border-grey {
      border-top: 1px solid #929292;
    }

  .c-accordions__dropdown {
    cursor: pointer;
    width: 100%;
    padding: $spacing-base 0;
    position: relative;

    &:hover {
        .c-accordions__title {
            color: darken($color-primary, 15%);
        }
        i {
            color: darken($color-primary, 15%);
        }
    }

    &.active {
      border-radius: 2px 2px  0 0;

      i {
        transform:  translateY(-50%) rotate(180deg);
      }

    }

  }

  .c-accordions__dropdown--alt {
      padding: 15px 0;
  }

    .c-accordions__title {
      transition: color 300ms ease-in-out, opacity 300ms ease-in-out;
      margin-bottom: 0;
      color: $color-primary;
      max-width: 85%;
      @include font-size(20px, 30px);
      @include font-primary--normal;
    }

    i {
      transition: color 300ms ease-in-out, transform 300ms ease-in-out, opacity 300ms ease-in-out;
      position: absolute;
      right: $spacing-half;
      font-size: 24px;
      color: $color-primary !important; 
      top: 50%;
      transform: translateY(-50%); 
      background: #fff;
      padding: 0 0.35rem;
      border-radius: 50%;
    }


  .c-accordions__content {
    display: none;
    padding-bottom: 30px;
  }

    .c-accordions__info {
      color: $color-primary;

      p, a {
        color: $color-primary;
      }

    }




  &.c-accordions--alt {
    border-color: #fff;
    .c-accordions__dropdown {
    }
    .c-accordions__title {
        color: #fff;
    }

    i {
        color: #fff;
    }
    .c-accordions__content {
        .c-accordions__info {
          color:#fff;

          p, a {
            color:#fff;
          }

        }
    }
  }    



}


.c-accordions--primary {
    border-top: 1px solid #fff;

    .c-accordions__dropdown {
        &:hover {
            .c-accordions__title {
               opacity: 0.8;
               color: #fff;
            }
            i {
                opacity: 0.8;
                color: $color-secondary !important;
            }
        }

        i {
            color: $color-primary !important;
        }
    }

    .c-accordions__title {
        color: #fff;
    }

    i {
        color: #fff;
    }

    .c-accordions__content {
        .c-accordions__info {
          color:#fff;

          p, a {
            color:#fff;
          }

        }
    }
}



.c-accordions--secondary {
    border-top: 1px solid #fff;
    
    .c-accordions__dropdown {
        &:hover {
            .c-accordions__title {
              opacity: 0.8;
              color: #fff;
            }
            i {
              opacity: 0.8;
              color: $color-primary !important;
            }
        }
        i {
            color: $color-secondary !important;
        }
    }

    .c-accordions__title {
        color: #fff;
    }

    i {
        color: #fff;
    }

    .c-accordions__content {
        .c-accordions__info {
          color:#fff;

          p, a {
            color:#fff;
          }

        }
    }
}
    

    .c-accordions__language {
        margin-bottom: 0;
    }