/* ———————————————————————————————————————— */
/* Default Page
/* ———————————————————————————————————————— */

body {
    overflow-x: hidden;
}

html {
  font-size: $global-font-size;
  line-height: $global-line-height / $global-font-size;
  background: $global-backgroiund;
  font-weight: $global-font-weight;
  font-family: $global-font-family;
  color: $global-text-color;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}





div {
  position: relative;
  outline: 0;
}

.margin-Right {
  margin-right:40px;
}