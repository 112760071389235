/* ———————————————————————————————————————— */
/* Components - Clinic Tabs
/* ———————————————————————————————————————— */

.c-clinic-tabs {
    // padding-top: $spacing-alt-double;
    padding-bottom: $spacing-triple;
    margin-top: 40px;

    
    .c-clinic-tabs__item {
        transition: color 300ms ease-in-out;
        text-transform: uppercase;
        color: #b8b8b8;
        margin-bottom: $spacing-half;
        @include font-size(20px, 30px);
        cursor: pointer;


        &.active {
            color: $color-secondary;
            font-weight: 600;
        }

        &:hover {
            color: rgba($color-secondary, 0.8);
        }

    }



    .c-clinic-tabs__button {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }
    
    
    
    @include responsive(tablet) {
        padding-top: 0;
        padding-bottom: $spacing-double;
    }
    
}