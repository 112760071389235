/* ———————————————————————————————————————— */
/* Components - Contact Form
/* ———————————————————————————————————————— */

.c-contact-form {
  padding-top: $spacing-base-alt;
  padding-bottom: $spacing-triple;
  

  .c-contact-form__curve {
    fill: $color-primary;
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: scale(1.05);
  }


  .c-contact-form__title {
    margin-bottom: $spacing-alt-triple;
    color: #fff;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }

  }
  

  .nf-form-fields-required, .nf-fu-progress, .nf-error-required-error {
    display: none;
  }


  select {
    border: 1px solid #fff;
    appearance: none;
      -webkit-appearance: none;
        -moz-appearance: none;
    outline: 0;
    border: 0;
    padding: $spacing-half $spacing-base-alt;
    margin-right: $spacing-half;
    color: #a3a3a3;
  }

  input, textarea {
    border: 1px solid #fff;
    resize: none;

    &::placeholder {
      color: #a3a3a3;
    }

  }

  textarea {
    height: 190px !important;
  }
  
  .nf-cell:first-of-type {
    padding-right: 20px;

    @include responsive(tablet) {
      width: 100%;
      padding-right: 0;
      padding-left: 0; 
    }

  }

  .nf-cell:last-of-type {
    padding-left: 20px;

    @include responsive(tablet) {
      width: 100%;
      padding-right: 0;
      padding-left: 0; 
    }

  }

  .nf-row:last-of-type .nf-cell {
    padding: 0;
  }
  
  .nf-fu-fileinput-button {
    transition: background 300ms ease-in-out;
    outline: 0;
    width: 100%;
    border-radius: 3px;
    padding: $spacing-half 0;
    background-color: transparent;
    border: 1px dashed #fff;

    span {
      transition: color 300ms ease-in-out;
      color: #fff;
      text-transform: uppercase;
    }

    &:hover {
      background-color: #fff;
      
      span {
        color: $color-primary;
      }

    }

  }


  input[type="button"] {
    border: 0 !important;
    padding-left: 60px;
    padding-right: 60px;
  }

  .submit-container {
    margin-bottom: 0;
  }

  #nf-field-8-container,  #nf-field-10-container {
    margin-bottom: 0;
  }


  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }


}