/* ———————————————————————————————————————— */
/* Tools - Fonts
/* ———————————————————————————————————————— */

// A simple font mixin that uses the global setting
// or the overrides set in base CSS, you may want
// to add more if you have more fonts on your project.
// Example: 
// @include font-primary--light;

/*--------------
  Primary Font
--------------*/

@mixin font-primary--light() {
  font-family: $primary-font;
  font-weight: $font-light;
}


@mixin font-primary--normal() {
  font-family: $primary-font;
  font-weight: $font-normal;
}


@mixin font-primary--medium() {
  font-family: $primary-font;
  font-weight: $font-medium;
}


@mixin font-primary--semi() {
  font-family: $primary-font;
  font-weight: $font-semi;
}


@mixin font-primary--bold() {
  font-family: $primary-font;
  font-weight: $font-bold;
}





/*--------------
 Secondary Font
--------------*/

@mixin font-secondary--light() {
  font-family: $secondary-font;
  font-weight: $font-light;
}


@mixin font-secondary--normal() {
  font-family: $secondary-font;
  font-weight: $font-normal;
}


@mixin font-secondary--medium() {
  font-family: $secondary-font;
  font-weight: $font-medium;
}


@mixin font-secondary--semi() {
  font-family: $secondary-font;
  font-weight: $font-semi;
}


@mixin font-secondary--bold() {
  font-family: $secondary-font;
  font-weight: $font-bold;
}