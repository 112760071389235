/* ———————————————————————————————————————— */
/* Components - Treatment Content
/* ———————————————————————————————————————— */

.c-treatment-content {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;

  .c-treatment-content__curve {
    fill: #fff;
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 2;
    transform: scale(1.05); 

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }

   .c-treatment-content__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include image(cover, center);
   }

    
   .c-treatment-content__overlay {
        background-color: rgba($color-secondary, 0.65);
        @include cover(1);
   }


  .c-treatment-content__content-image {
    max-height: 400px;
    border-radius: 3px;
    z-index: 2;
    @include image(cover, center);
    @include responsive(tablet) {
      padding-top: $spacing-half;
    }
  }


  .c-treatment-content__title {
    font-family: $primary-font;
    @include font-primary--light;
    margin-bottom: $spacing-alt-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }
  }

  .c-treatment-content__text {
    z-index: 2;
    @include font-secondary--light;

    h3, h4, h5, h6 {
        color: $color-primary;
    }

    ul {
        margin-bottom: $spacing-base;
    }

    li {
        margin-bottom: 5px;
        line-height: 26px;
        
        &:last-of-type {
            margin-bottom: 0;
        } 
    }
  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}


.c-treatment-content--primary {

  .c-treatment-content__curve,
  .c-treatment-before-after__curve,
  .c-treatment-comparison__curve,
  .c-treatment-video__curve {
    fill: $color-primary;
  }

  .c-treatment-content__text {
    color: #fff;
    @include font-secondary--light;

    h3, h4, h5, h6, p, h2, h1 {
        color: #ffffff;
    }

    ul {
        margin-bottom: $spacing-base;
    }

    li {
        margin-bottom: 5px;
        line-height: 26px;
        
        &:last-of-type {
            margin-bottom: 0;
        } 
    }
  }

    h3, h4, h5, h6, p, h2, h1 {
        color: #ffffff;
    }

}

.c-treatment-content--secondary {

  .c-treatment-content__curve,
  .c-treatment-before-after__curve,
  .c-treatment-comparison__curve,
  .c-treatment-video__curve {
    fill: $color-secondary;
  }

  .c-treatment-content__text {
    color: #fff;
    @include font-secondary--light;

    h3, h4, h5, h6, p, h2, h1 {
        color: #ffffff;
    }

    ul {
        margin-bottom: $spacing-base;
    }

    li {
        margin-bottom: 5px;
        line-height: 26px;
        
        &:last-of-type {
            margin-bottom: 0;
        } 
    }
  }

    h3, h4, h5, h6, p, h2, h1 {
        color: #ffffff;
    }

    .c-treatment-content__title {
        color: #fff;
    }

}

.c-treatment-content--grey {

  .c-treatment-content__curve,
  .c-treatment-before-after__curve,
  .c-treatment-comparison__curve,
  .c-treatment-video__curve {
    fill: $color-grey;
  }

  .c-treatment-content__text {
    // color: #fff;
    @include font-secondary--light;

    h3, h4, h5, h6, h2, h1 {
        color: $color-primary;
    }

    ul {
        margin-bottom: $spacing-base;
    }

    li {
        margin-bottom: 5px;
        line-height: 26px;
        
        &:last-of-type {
            margin-bottom: 0;
        } 
    }
  }

    h3, h4, h5, h6, h2, h1 {
        color: $color-primary;
    }

.c-treatment-content__title {
    color: $color-primary;
}

}

.c-treatment-content--white {

  .c-treatment-content__text {
    @include font-secondary--light;
  }

  h3, h4, h5, h6, h2, h1 {
      color: $color-primary;
  }

.c-treatment-content__title {
    color: $color-primary;
}

}


.c-treatment-content__curve--alt {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05); 
}



.c-treatment-content--image {
    padding-top: 200px;
    margin-top: -150px;
    padding-bottom: 75px;
    color: #fff;


    .c-treatment-content__title {
        color: #fff !important;
    }


    h3, h4, h5, h6, p, h2, h1 {
        color: #ffffff;
    }




    @include responsive(tablet) {
        padding-top: 100px;
        padding-bottom: -30px;
        margin-top: -50px;
    }

    @include responsive(mobile) {
        padding-top: 50px;
    }

}

// .treatment{
//   ul{
//     list-style: none;
//     li{
//       &:before{
//         content: '\f058';
//         color: $color-primary;
//         font-family: FontAwesome;
//         position: absolute;
//         left: 0;
//       }
//     }
//   }
// }