/* ———————————————————————————————————————— */
/* Components - Price Block
/* ———————————————————————————————————————— */

.c-price-block {
  overflow: visible !important;
  margin-top: $spacing-alt-double;
  

  .c-price-block__price {
    background-color: $color-secondary;
    color: #fff;
    padding: $spacing-double 0;
    text-align: center;
    border-radius: 3px 3px 0 0;
    letter-spacing: 0.6px;
    @include font-size(60px, 70px);
    @include font-primary--bold;
  }

  
  .c-price-block__coverage {
    transition: background 300ms ease-in-out;
    background-color: $color-primary;
    color: #fff;
    text-transform: uppercase;
    padding: 20px $spacing-base-alt;
    letter-spacing: 0.4px;
    border-radius: 0 0 3px 3px;
    @include font-primary--semi;

    i {
      pointer-events: none;
      position: absolute;
      right: $spacing-base-alt;
      top: 50%;
      transform: translateY(-50%);
    }

    &.active {
      border-radius: 0;
    }

  }


  .c-price-block__title {
    margin-top: $spacing-alt-double;
    margin-bottom: $spacing-base-alt;
    color: $color-primary;
    @include font-primary--semi;
  }

  .c-price-block__intro {
    @include font-secondary--light;
  }
  

  .c-price-block__list {
    display: none;
    background-color: $color-primary--dark;
    color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 10;
    border-radius: 0 0 3px 3px;
    max-height: 500px;
    overflow: scroll;
  }

    .c-price-block__item {
      transition: background 300ms ease-in-out;
      background-color: darken($color-primary--dark, 15);
      padding: 10px $spacing-base-alt;
      letter-spacing: 0.4px;
      border-top: 1px solid $color-primary;
      line-height: 22px;
      font-size: 13px;
      @include font-primary--semi;
    }
  

  &:nth-of-type(-n+2) {
    margin-top: 0;
  }

  
  @include responsive(tablet) {
    margin-top: $spacing-alt-double !important;

    &:nth-of-type(-n+1) {
      margin-top: 0 !important;
    }

  }


}