/* ———————————————————————————————————————— */
/* Tools - Clearfix
/* ———————————————————————————————————————— */

// Simple mixin to clear floats just use: 
// @include clearfix;

@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
} 