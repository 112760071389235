/* ———————————————————————————————————————— */
/* Object - Header
/* ———————————————————————————————————————— */

.o-header {
  top: 0;
  left: 0;
  z-index: 15;
  background-color: $color-primary--dark;


  .o-header__wrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }


  .o-header__mobile {
    display: none;
    margin-left: auto;
    z-index: 6;
    padding: $spacing-base 0;

    @include responsive(laptop) {
      display: block;
    }
    
    .o-header__mobile-item {
      color: #fff;
      font-size: 30px;
      line-height: 30px;

      &:first-of-type {
        margin-right: $spacing-base;
      }

    }
  
  }


  .o-header__search {
    transition: top 300ms ease-in-out;
    position: absolute;
    top: -100%;
    left: 0;
    z-index: 25;
    background-color: $color-primary--dark;

    &.active {
      top: 0;
    }

    &.o-header__search-alt {
      background-color: $color-secondary--dark
    }

    @include responsive(mobile) {
      z-index: 20;
    }

  }


}




.o-header-finance {
  transition: opacitY 300ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  background-color: rgba(#000, 0.4);
  overflow-y: scroll;

  .o-container {
    height: 100%;
  }

  &.active {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }
  
}





.o-header-top {
  height: 20px;
  width: 100%;
  display: block;
  background-color: $color-primary--dark;
  padding: 10px 0;
  @include clearfix;

  &.o-header-top--alt {
    background-color: $color-secondary--dark
  }

  img {
    margin-right: $spacing-base;
  }

  .o-header-top__button {
    float: right;
  }

}


.o-header-overlay {
  transition: height 300ms ease-in-out;
  position: absolute;
  top: 160px;
  left: 0;    
  z-index: 15;
  height: 0px;
  width: 100%;
  display: block;
  background-color: $color-secondary;

}


.o-header-video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(#000, 0.5);
  z-index: 99;

  .o-container {
    height: 100%;
  }

  .o-header-video__wrapper {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    display: block;
  }

  iframe {
    width: 100%;
    background-color: #fff;
    height: 75vh;
  }

  .o-header-video__close {
    transition: background 300ms ease-in-out;
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: $color-primary;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    color: #fff;

    i {
      top: 50%;
      transform: translateY(-50%);
      position: relative;
    }

    &:hover {
      background-color: darken($color-primary, 15);
    }

  }

}



.c-countdown {
	padding: 15px 0;
	background-color: #000;
	overflow: hidden;
}


	.c-countdown__small {
		left: 25%;
		bottom: -30px;
		z-index: 1;
	}

	.c-countdown__medium {
		top: -400px;
		right: -150px;
		z-index: 1;
	}

	.c-countdown__large {
		top: -27vw;
		left: -27vw;
		z-index: 1;
	}


	.c-countdown__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 4;
		position: relative;
	}

	
		.c-countdown__text {
			color: #fff;
			margin-bottom: 0;
			font-weight: 700;
			font-size: 23px;
			line-height: 26px;
			margin-right: 10px;

			@include responsive(laptop) {
				display: none;
			}

			@include responsive(mobile) {
				font-size: 16px;
				line-height: 18px;
				margin-right: 10px;
			}
		}


		.c-countdown__mobile {
			display: none;

			@include responsive(laptop) {
				display: block;
			}
		}


		.c-countdown__mobile-date {
			color: #fff;
			font-size: 16px;
			line-height: 20px;
			margin-right: 20px;
			text-transform: uppercase;

			@include responsive(mobile) {
				font-size: 14px;
				line-height: 18px;
				margin-right: 10px;
			}
		}


		.c-countdown__right {
			display: flex;
			align-items: center;

			@include responsive(mobile) {
				width: 100%;
			}
		}


			.c-countdown__label {
				margin-right: 20px;
				color: #fff;
				text-transform: uppercase;

				@include responsive(laptop) {
					display: none;
				}
			}


			.c-countdown__timer {
				border: 1px solid #fff;
				position: relative;
				display: flex;
				margin-right: 23px;

				@include responsive(laptop) {
					display: none;
				}
			}


				.c-countdown__item {
					display: block;
					width: 65px;
					text-align: center;
					color: #fff;
					padding: 6px 0;
					border-left: 1px solid #fff;
					font-size: 16px;
					line-height: 18px;
					text-transform: uppercase;

					&:first-of-type {
						border-left: 0;
					}
				}


				.c-countdown__date {
					width: 100%;
					display: block;
				}


	.c-countdown__button {
		min-width: 180px;
		text-align: center;

		@include responsive(mobile) {
			min-width: 65px;
		}
	}