/* ———————————————————————————————————————— */
/* JD - Base Headings
/* ———————————————————————————————————————— */

// Overrides 
// $h1-heading-size: 40px;
// $h2-heading-size: 38px;
// $h3-heading-size: 32px;
// $h4-heading-size: 26px;
// $h5-heading-size: 22px;
// $h6-heading-size: 18px;

// $h1-line-height: 50px;
// $h2-line-height: 48px;
// $h3-line-height: 38px;
// $h4-line-height: 26px;
// $h5-line-height: 22px;
// $h6-line-height: 18px;


h1 {
  color: $color-heading;
  font-weight: 700;
  @include font-size($h1-heading-size, $h1-line-height);

  @include responsive(tablet) {
    @include font-size(30px, 40px);
  }

}



h2 {
  color: $color-heading;
  font-weight: 300;
  @include font-size($h2-heading-size, $h2-line-height);

  @include responsive(tablet) {
    @include font-size(25px, 35px);
  }

}



h3 {
  color: $color-heading;
  font-weight: 300;
  @include font-size($h3-heading-size, $h3-line-height);
  
  @include responsive(tablet) {
    @include font-size(25px, 35px);
  }

}



h4 {
  color: $color-heading--alt;
  @include font-size($h4-heading-size, $h4-line-height);
}


h5 {
  color: $color-heading--alt;
  @include font-size($h5-heading-size, $h5-line-height);
}


h6 {
  color: $color-heading--alt;
  @include font-size($h6-heading-size, $h6-line-height);
}


h1,h2,h3,h4,h5,h6 {
  margin-bottom: $spacing-base;
} 