/* ———————————————————————————————————————— */
/* Components - Why Row
/* ———————————————————————————————————————— */

.c-why-row {
    padding-bottom: $spacing-triple;

    @include responsive(tablet) {
      padding-top: 0;
      padding-bottom: 0;
    }


    .c-why-row__curve {
        position: absolute;
        bottom: 100%;
        left: 0;
        fill: $color-primary;
        transform: scale(1.05); 

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }
   
}


    .c-why-row__title {
        margin-bottom: 60px;
        color: #fff;

        @include responsive(tablet) {
            margin-bottom: 30px;
        }
    }


    .c-why-row__item {
        margin-top: $spacing-base;

        &:nth-of-type(-n+3) {
            margin-top: 0;
        }

        @include responsive(tablet) {
            margin-top: $spacing-base;

            &:nth-of-type(2) {
                margin-top: $spacing-base;
            }

            &:nth-of-type(3) {
                margin-top: $spacing-base;
            }

            &:first-of-type {
                margin-bottom: 0;
            }

        }


        @include responsive(mobile) {
            margin-top: 15px;

            &:nth-of-type(2) {
                margin-top: 15px;
            }

            &:nth-of-type(3) {
                margin-top: 15px;
            }

            &:first-of-type {
                margin-bottom: 0;
            }

        }
    }


    .c-why-row__inner {
        display: flex;
        align-items: center;
    }

        .c-why-row__icon {
            background-color: $color-secondary;
            width: 55px;
            height: 55px;
            min-width: 55px;
            min-height: 55px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 28px;
            margin-right: 20px;

            @include responsive(mobile) {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                margin-right: 15px;
                font-size: 18px;
            } 
        }


        .c-why-row__item-title {
            margin-bottom: 0;
            margin-right: 35px;
            font-size: 28px;
            line-height: 38px;

            @include responsive(tablet) {
                margin-right: 0;
            }

            @include responsive(mobile) {
                font-size: 18px;
                line-height: 28px;
            }
        }