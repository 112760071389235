/* ———————————————————————————————————————— */
/* Components - Related Treatments
/* ———————————————————————————————————————— */

.c-treatment-related {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-treatment-related__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: #fff;
    transform: scale(1.05); 

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }

    &.c-treatment-related__curve--alt {
        fill: $color-primary;
    }
  }


  .c-treatment-related__title {
    margin-bottom: $spacing-alt-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }


}




.c-treatment-related--primary {
    .c-treatment-related__curve {
        fill: $color-primary;
    }

    .c-treatment-related__title {
        color: #fff;
    }
}


.c-treatment-related--secondary {
    .c-treatment-related__title {
        color: #fff;
    }
    .c-treatment-related__curve {
        fill: $color-secondary;
    }
}


.c-treatment-related--grey {
    .c-treatment-related__curve {
        fill: $color-grey;
    }
}