/* ———————————————————————————————————————— */
/* Components - News
/* ———————————————————————————————————————— */

.c-news {
  position: relative;
  display: block;
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-news__filters {
    margin-bottom: $spacing-alt-double;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include responsive(mobile) {
      display: inline-block;
      width: 100%;
    }

  }

    .c-news__filters-name {
      text-transform: uppercase;
      margin-right: 15px;

      @include responsive(mobile) {
        margin-bottom: $spacing-half;
      }

    }

  .c-news__wrapper {
  }

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}