/* ———————————————————————————————————————— */
/* Components - grand Nav
/* ———————————————————————————————————————— */

.c-grand-nav {
  margin-top: 30px;
  position: relative;

  .c-grand-nav__item {
    transition: opacity 300ms ease-in-out;
    position: relative;
    width: 100%;
    margin-bottom: 8px;

    &:last-of-type {
      padding-bottom: $spacing-double;
    }

    &:hover {
        opacity: 1 !important;
    }
    
  }  

    .c-grand-nav__link {
      transition: opacity 300ms ease-in-out;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      line-height: 22px;
      @include font-primary--normal;
    }

}
