/* ———————————————————————————————————————— */
/* Components - Reviews Row
/* ———————————————————————————————————————— */

.c-reviews-row {
    padding-bottom: $spacing-triple;

    @include responsive(tablet) {
      padding-top: 0;
      padding-bottom: 0;
    }


    .c-reviews-row__curve {
        position: absolute;
        bottom: 100%;
        left: 0;
        fill: #fff;
        transform: scale(1.05); 

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
            display: none;
        }
    }

    iframe {
        position: relative;
        z-index: 2;
    }
   
}


    .c-reviews-row__title {
        margin-bottom: 60px;
    }


    .c-reviews-row__item {
        padding: 40px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 15px 2px rgba(0,0,0,0.15);
        
        @include responsive(tablet) {
            margin-top: 25px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }


    .c-reviews-row__item--video {
        min-height: 230px;
    }


    .c-reviews-row__loader {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: $color-primary;
    }


    .c-reviews-row__video {
        width: 100%;
        height: 100%;
        min-height: 230px;
        position: absolute;
        top: 0;
        left: 0;
    }



        .c-reviews-row__iframe {
            width: 100%;
            height: 100%;
        }


        .c-reviews-row__image {
            border-radius: 3px;
            @include cover;
            @include image(cover, center);
            z-index: 10;
        }



        .c-reviews-row__icon {
            position: absolute;
            cursor: pointer;
            transition: background 300ms ease-in-out;
            bottom: 25px;
            left: 25px;
            height: 40px;
            width: 40px;
            background-color: #fff;
            color: #fff;
            border-radius: 50%;
        
            i {
              text-align: center;
              top: 50%;
              transform: translateY(-50%);
              position: relative;
              width: 100%;
              font-size: 22px;
              color: $color-primary;
            }
        
            &:hover {
              background-color: darken(#fff, 15);
            }
        
          }
        