/* ———————————————————————————————————————— */
/* Components - Clinic Latest
/* ———————————————————————————————————————— */


.c-clinic-latest {
  margin-bottom: 50px;


  .c-clinic-latest__title {
    margin-bottom: $spacing-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }

}