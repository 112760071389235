/* ———————————————————————————————————————— */
/* Components - Banner
/* ———————————————————————————————————————— */

.c-banner {
  height: 550px;
  background-color: $color-primary;
  overflow: hidden;
  @include image(cover, center);

  &.c-banner--alt {
    background-color: $color-secondary;
  }

  &.c-banner__short {
    height: 400px;
  }


  .c-banner__bubble {
    transition: left 1s;
    position: absolute;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    transform: translateY(-50%) scale(4, 4);
    top: 50%;
    overflow: hidden;

    @media (min-width: 1500px) {
      transform: translateY(-50%) scale(5, 5);
    }

    @media (min-width: 2350px) {
      transform: translateY(-50%) scale(6, 6 );
    }

  }

  .c-banner__bubble--main {
    transform: translateY(-50%) scale(5.2, 5.2);
    left: -5%;
    top: -20%;
    opacity: 0.1;
    z-index: 2;
    background-color: #fff;

    &.image {
        opacity: 0.3;
        background-color: $color-primary;
    }

    @media (min-width: 1500px) {
      transform: translateY(-50%) scale(6.5, 6.5);
    }

    @media (min-width: 1900px) {
      transform: translateY(-50%) scale(7.5, 7.5);
    }

    @media (min-width: 2100px) {
      transform: translateY(-50%) scale(8.2, 8.2);
    }

    @media (min-width: 2200px) {
      transform: translateY(-50%) scale(8.7, 8.7 );
    }

    @media (min-width: 2350px) {
      transform: translateY(-50%) scale(9.5, 9.5 );
    }

  }


  .c-banner__bubble--image {
    left: 85%;
    right: -5%;
    opacity: 1;
    z-index: 1;
    @include image(contain, left);

    @include responsive(laptop) {
      display: none;
    }

  }
    
    .c-banner__darken {
      background-color: rgba(#000, 0.2);
      @include cover;
    }


  .c-banner__bubble--small {
    background-color: #fff;
    opacity: 0.1;
    width: 300px;
    height: 300px;
    top: 35%;
    left: 55%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 3;
  }


  .c-banner__map {
    opacity: 0.3;
    @include cover;
    z-index: 8;
  }

  .c-banner__image {
    opacity: 0.3;
    @include cover;
    z-index: 8;
    width: 100%;
  }

  .c-banner__map-overlay {
    background-color: $color-primary--dark;
    @include cover;
  }


  .o-container {
    height: 100%;
  }

  .c-banner__content {
    z-index: 9;
    top: 55%;
    transform: translateY(-50%);
  }
    
    .c-banner__title {
      color: #fff;
      letter-spacing: 0.8px;
      max-width: 55%;

      @include responsive(tablet) {
        max-width: 100%;
      }

    }

    .c-banner__sub-title {
      color: #fff;
      max-width: 55%;
      @include font-size(25px, 35px);
      
      @include responsive(tablet) {
        max-width: 100%;
        @include font-size(15px, 24px);
      }

    }

    .c-banner__button {
      padding-left: $spacing-double;
      padding-right: $spacing-double;
    }

      .c-banner__button--booking {
        margin-left: 20px;

        @include responsive(mobile) {
          margin-left: 0;
          margin-top: 15px;
        }
      }


    .c-banner__info {
      margin-top: $spacing-alt-double;
      display: inline-block;
      margin-right: $spacing-half;
      color: #fff;
      @include font-secondary--light;

      &:last-of-type {
        margin-right: 0;
      }

      span {
        margin-right: $spacing-quarter;
        text-transform: uppercase;
        @include font-secondary--semi;
      }

    }


  .c-banner__curve {
    fill: #fff;
    width: 100%;
    z-index: 8;
    position: absolute;
    bottom: 0;
    transform: scale(1.05);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }


  .c-banner__curve--secondary {
    fill: $color-secondary;
  }


  .c-banner__curve--black {
    fill: #000;
  }


  @include responsive(tablet) {
    height: 450px;
  }

}
