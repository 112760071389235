/* ———————————————————————————————————————— */
/* Components - About Contact
/* ———————————————————————————————————————— */


.c-about-contact {
  z-index: 2;
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-about-contact__curve {
    position: absolute;
    bottom: 100%;
    left: 0;
    fill: $color-primary;
    transform: scale(1.05);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        display: none;
    }
  }

  &.c-about-contact--secondary {
    .c-about-contact__curve {
      fill: $color-secondary;
    }
  }


  .c-about-contact__title {
    color: #fff;
    margin-bottom: $spacing-alt-double;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }

  }
  

  .c-about-contact__title--mobile {
    @include responsive(tablet) {
      margin-top: $spacing-double;
    }
  }


  .c-about-contact__intro {
    max-width: 50%;

    @include responsive(tablet) {
      max-width: 75%;
    }

    @include responsive(mobile) {
      max-width: 100%;
    }
  }


  &.c-about-contact-map {
    .nf-form-cont .nf-cell {
      width: 100% !important;
    }
    .c-about-contact-map__flex {
      display: flex;
      flex-wrap: wrap;
      .c-about-contact-map__left {
        width: 50%;
        @include responsive(tablet) {
          width: 100%;
        }
        .c-btn--secondary {
          background-color: $color-primary;
        }
      }
      .c-about-contact-map__right {
        width: 50%;
        @include responsive(tablet) {
          width: 100%;
          .c-about-contact__title {
            margin-top: 50px;
          }
        }
      }
    }
    .c-contact__map {
      height: 350px;
    }
    .c-about-contact__details {
      margin-top: 50px;
      p, a {
        color: #ffffff;
        margin-bottom: 30px;
        font-size: 15px;
        line-height: 26px;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
  }


  .nf-form-fields-required, .nf-fu-progress, .nf-error-required-error {
    display: none;
  }


  select {
    border: 1px solid #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    border: 0;
    border-radius: 3px;
    padding: $spacing-half $spacing-base-alt;
    margin-right: $spacing-half;
    color: #a3a3a3;
    background-color: #fff;
  }

  select::-ms-expand{
    display: none;
    color: #a3a3a3;
  }

  input, textarea {
    border: 1px solid #fff;
    resize: none;

    &::placeholder {
      color: #a3a3a3;
    }

  }

  textarea {
    height: 190px !important;
  }


  .file_upload-container {
    margin-bottom: 10px;

    .nf-element {
      display: none !important;
    }

    .files_uploaded {
      position: relative !important;

      p {
        margin-bottom: 10px;
      }

    }
  }

  
  .nf-fu-fileinput-button {
    transition: background 300ms ease-in-out;
    outline: 0;
    width: 100%;
    border-radius: 3px;
    padding: $spacing-half 0;
    background-color: transparent;
    border: 1px dashed #fff;

    span {
      transition: color 300ms ease-in-out;
      color: #fff;
      text-transform: uppercase;
    }

    &:hover {
      background-color: #fff;
      
      span {
        color: $color-primary;
      }

    }

  }

  .files_uploaded {
    position: absolute;

    p {
      color: #fff;
    }

    a {
      color: $color-text;
      margin-left: 25px;
      @include font-primary--semi;
    }

  }


  input[type="button"] {
    border: 0 !important;
    padding-left: 60px;
    padding-right: 60px;
  }

  .submit-container {
    margin-bottom: 0;
  }

  #nf-field-8-container,  #nf-field-10-container {
    margin-bottom: 0;
  }


  @include responsive(tablet) {
    padding-top: 0; 
    padding-bottom: 0;
  } 


}