/* ———————————————————————————————————————— */
/* Components - Benefits
/* ———————————————————————————————————————— */

.c-home-benefits {
    padding-bottom: 125px;

    @include responsive(tablet) {
        padding-bottom: $spacing-base;
    }


    .c-home-benefits__title {
        color: $color-secondary;
        margin-bottom: $spacing-double;
    }

}