/* ———————————————————————————————————————— */
/* JD - Base Text
/* ———————————————————————————————————————— */

p {
  color: $color-text;
  margin-bottom: $spacing-base;
  @include font-size($global-font-size, $global-line-height);
}

.treatment{
	strong,b{
		font-weight: 600;
		color: $color-secondary;
	}
	em{
		font-style: italic;
	}
}

.link{
	&-white{
		color: #ffffff;
	}
}
