/* ———————————————————————————————————————— */
/* Components - Clinic Block
/* ———————————————————————————————————————— */

.c-clinic-block {
  margin-top: $spacing-alt-triple;
  padding-bottom: $spacing-base;
  border-bottom: 2px solid $color-grey;


  
  .c-clinic-block__title {
    color: $color-primary;
    margin-bottom: 0;
    @include font-primary--semi;
  }


  .c-clinic-block__phone {
    width: 100%;
    display: inline-block;
    margin-bottom: $spacing-half;
    color: $color-text;
    @include font-size(20px, 30px);

    &:hover {
        color: $color-secondary;
    }

  }


  .c-clinic-block__button {
    width: 65px;
    height: 50px;
    padding: 0;
    float: left;
    position: relative;
    margin-right: $spacing-half;

    i {
      position: relative;
      width: 100%;
      text-align: center;
      top: 50%;
      font-size: 20px;
      transform: translateY(-50%);
    }

    &:last-child {
      margin-right: 0;
    }

  }


  .c-clinic-block__list {
    margin-bottom: $spacing-base;
  }

  .c-clinic-block__list--alt {
    margin-bottom: 0;
  }

    .c-clinic-block__item {
        @include font-size(20px, 30px);
    }



  &.c-clinic-block--no-margin {
    margin-top: 0;
  }

  &.c-clinic-block--address {
    border-bottom: none;
    li, p, a {
      color: $color-text;
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 26px;
      font-weight: 300;
      margin-bottom: 0;
    }
  }
  
}