/* ———————————————————————————————————————— */
/* Components - Clincs
/* ———————————————————————————————————————— */

.c-clinics {
  // padding-top: $spacing-alt-double;
  padding-bottom: $spacing-triple;


  .c-clinics__title {
    float: left;
    margin-bottom: $spacing-alt-double;
    color: $color-primary;

    @include responsive(tablet) {
      margin-bottom: $spacing-base;
    }
    
    &.last {
        margin-bottom: 0;
        margin-top: 65px;
    }

  }

  .c-clinics__map {
    width: 100%;
    height: 600px;

    @include responsive(tablet) {
      height: 450px;
    }

  }
  

  .c-clinics__tabs {
    margin-top: 10px;
    list-style-type: none;
    display: inline-block;
    float: right;
    position: relative;
    z-index: 2;
    color: $color-secondary;


    &:hover {
        .c-clinics__tabs-item {
            opacity: 0.3;
        }
    }

    @include responsive(tablet) {
        margin-bottom: 50px;
    }

    &.last {
        margin-top: 75px;

        @include responsive(tablet) {
            margin-bottom: 0;
        }

    }

  }

    .c-clinics__tabs-item {
        transition: opacity 300ms ease-in-out;
        cursor: pointer;
        margin: 0;
        display: inline-block;
        padding: 0 25px;
        border-left: 1px solid $color-secondary;
        font-weight: 300;
        @include font-size(20px, 30px);

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }

        &.active {
            opacity: 1 !important;
            color: $color-primary;

            &:hover {
                opacity: 1 !important;
            }

        }

        &:hover {
            opacity: 1 !important;
        }

        @include responsive(tablet) {
            border-left: 0;
            width: 100%;
            padding: 0;
            margin-left: 0;
        }
    }


  .c-clinics__wrapper {
    display: none;
    width: 100%;

    &.active {
      display: flex;
    }
    
  }
  

  @include responsive(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

}